Vue.asyncComponent('ak-ui-kit', {
    name: 'ak-ui-kit',
    data() {
        return {
            modal1Visible: false,
            modal2Visible: false
        }
    },
    methods: {
        test() {
            console.log('test');
        },
    }
}, 'utilities/ak-ui-kit.html');