import FormElementMixin from '../mixins/FormElementMixin.js';
import VueAutonumeric from "vue-autonumeric";


Vue.asyncComponent('ak-price', {
    name: 'ak-price',
    components: {
        VueAutonumeric
    },
    props: {
        prefix: {
            type: String,
            default: '€'
        },
        decimalPlaces: {
            type: Number,
            default: 2
        },
    },
    mixins: [FormElementMixin],
    computed: {
        /**
         * Get the decimalNumber we need to do calculations width e.g: if we have decimalPlaces 2 => decimalNumber needs to bee 0,01
         * @returns {number}
         */
        decimalNumber() {
            return Math.round(Math.pow(.1, this.decimalPlaces) * 100) / 100;
        },
        /**
         * The Options for autonumeric plugin
         * @returns {{digitGroupSeparator: string, unformatOnHover: boolean, decimalPlaces: number, decimalPlacesShownOnFocus: number, decimalCharacter: string, decimalPlacesShownOnBlur: number, showOnlyNumbersOnFocus: boolean, rawValueDivisor: number}}
         */
        autoNumericOptions() {
            return {
                decimalCharacter: ',',
                digitGroupSeparator: '',
                unformatOnHover: false,
                decimalPlaces: this.decimalPlaces,
                decimalPlacesShownOnFocus: this.decimalPlaces,
                decimalPlacesShownOnBlur: this.decimalPlaces,
                rawValueDivisor: this.decimalNumber,
                showOnlyNumbersOnFocus: false,
                modifyValueOnWheel: false,
            }
        }
    }
}, 'form/controls/ak-price.html');
