Vue.asyncComponent('ak-placeholder-list', {
    props: {
        show: {
            type: String,
        },
        highlightedIndex: {
            type: Number,
        },
        placeholders: {
            type: Array,
        }
    },
    methods: {
        insertPlaceholder(index, $event) {
            $event.preventDefault();

            this.$emit('insertPlaceholder', index);
        }
    }
}, 'form/controls/partials/ak-placeholder-list.html');