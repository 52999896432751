Vue.asyncComponent('ak-modal', {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        content: {
            type: String,
            required: false,
            default: ''
        },
        confirmText: {
            type: String,
            required: false,
        },
        cancelText: {
            type: String,
            required: false,
        },
        active: {
            type: Boolean,
            required: true,
        },
        /**
         * Can't close the modal on click outside or ESC
         */
        persistent: {
            type: Boolean,
            required: false,
            default: false
        },
        showButtons: {
            type: Boolean,
            required: false,
            default: true
        },
        maxWidth: {
            type: String,
            required: false,
            default: '80%'
        },
        width: {
            type: String,
            required: false,
            default: 'auto'
        },
        overlay: {
            type: Boolean,
            required: false,
            default: true
        },
        appendToRoot: {
            type: Boolean,
            required: false,
            default: false
        },
        closeLabel: {
            type: String,
            required: false,
        },
        verticalCenter: {
            type: Boolean,
            required: false,
            default: true
        },
        noPadding: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    model: {
        prop: 'active',
        event: 'change'
    },
    watch: {
        active(value) {
            if(value) {
                document.addEventListener('keyup', this.checkEscapePressed);
            } else {
                document.removeEventListener('keyup', this.checkEscapePressed);
            }
        }
    },
    methods: {
        onCancel(event) {
            this.$emit('onCancel', event);
        },
        onConfirm(event) {
            this.$emit('onConfirm', event);
        },
        close() {
            this.$emit('close');
            this.$emit('change', false);
        },
        clickOutside(e) {
            if (!this.persistent) {
                this.close();
            }
        },
        checkEscapePressed(e) {
            if (e.key === "Escape" && !this.persistent) {
                this.close();
            }
        }
    },
    mounted: function() {
        this.$root.$el.append(this.$el);
    },
    destroyed: function() {
        if(this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
    }
}, 'layout/ak-modal.html');