// function helpers

/**
 * A debounce function is a programming technique that ensures a specific action or event is triggered only once after
 * a defined delay, even if the triggering event occurs multiple times in rapid succession, helping to manage and
 * control user input and event handling.
 *
 * @param func
 * @param timeout
 * @returns {(function(...[*]): void)|*}
 */
export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}