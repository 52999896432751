Vue.asyncComponent('t', {
    props: {
        plural: {
            type: String,
            required: false
        },
        // We include "count" in the property list. This will ensure it is
        // watched and that the component is bound to its (changing) value.
        count: {
            type: Number,
            required: false,
            default: 0
        },
        // Each Vue component requires a root element, and this component is
        // no exception to this rule. By default, the component will render
        // a <span> element to wrap the translated text. You can however decide
        // to render a <a> element instead, by providing the tag name, "a", as
        // a prop to the component:
        tagName: {
            type: String,
            required: false,
            default: 'span'
        }
    },
    data() {
        return {
            translated: ''
        }
    },
    methods: {
        setPluralTranslated (newCount) {
            this.translated = this.$p(this.untranslated, this.plural, newCount, this.attributes);
        }
    },
    render(r) {
        return r(this.tagName, {class: 't'}, this.translated);
    },
    created() {
        // Create private variables. This hook is called Vue has already
        // finished the observation phase. So this is a good place to declare
        // private scope variables (that are not watched)
        // (note that we trim the untranslated string, to avoid having HTML
        //  indentation space in this string)
        this.untranslated = this.$slots.default[0].text.replace(/^\s+|\s+$/gm, '');
        this.attributes = this.$attrs;

        // Insert translation
        if (!this.plural) {
            this.translated = this.$t(this.untranslated, this.attributes);
            return;
        }

        // If plural, set translation for current count
        this.setPluralTranslated(this.count);
    },
    updated() {
        // We only need to update the translated text, if a plural form is
        // used. In that case, the count property might be bound, and the
        // translated text might need updating. If not though, we exit here
        // and we do not update!
        if (!this.plural) return;

        this.setPluralTranslated(this.count);
    }
}, null, false);