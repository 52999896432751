Vue.asyncComponent('ak-status', {
    props: {
        label: {
            type: String,
            required: false,
        },
        color: {
            type: Object,
            required: true,
        },
    }
}, 'layout/ak-status.html');