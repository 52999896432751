Vue.asyncComponent('ak-errors', {
    name: 'AkErrors',
    data() {
        return {
            errors: this.$errors,
        }
    },
    watch: {
        errors() {
            this.errors.forEach((error, key) => {
                setTimeout(() => this.removeError(key), 10000);
            })
        }
    },
    methods: {
        removeError(key) {
            this.$errors.splice(key, 1);
        }
    }
}, 'layout/ak-errors.html');