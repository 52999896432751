import ListWidgetMixin from "../mixins/ListWidgetMixin";

Vue.asyncComponent('ak-button-group', {
    mixins: [ListWidgetMixin],
    props: {
        items: {
            type: Array,
            required: false,
        },
        size: {
            type: String,
            required: false,
        },
    }
}, 'layout/ak-button-group.html');