Vue.asyncComponent('ak-video', {
    name:'ak-video',
    props: {
        src: {
            type: String,
            required: true
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        },
        showControls: {
            type: Boolean,
            default: true
        },
        muted: {
            type: Boolean,
        },
        loop: {
            type: Boolean,
        },
        autoplay: {
            type: Boolean,
        },
    },
}, 'layout/ak-video.html');