export default class operationHandler {

    /**
     *
     * @param value
     * @param operation
     * @param valueToCompare
     */
    constructor(value, operation, valueToCompare) {
        this.value = value;
        this.operation = operation;
        this.valueToCompare = valueToCompare;
    }

    /**
     * Will process the operation and return the result
     *
     * @returns {boolean}
     */
    result() {
        switch (this.operation) {
            case 'AND':
                return this.value && this.valueToCompare;

            case 'OR':
                return this.value || this.valueToCompare;

            case 'LT':
                return this.value < this.valueToCompare;

            case 'GT':
                return this.value > this.valueToCompare;

            case 'GTEQ':
                return this.value >= this.valueToCompare;

            case 'BEGINSWITH':
                return this.value.indexOf(this.valueToCompare) == 0;

            case 'ENDSWITH':
                return this.value.endsWith(this.valueToCompare);

            case 'IN':
                if (this.valueToCompare === '*') {
                    return this.value.length > 0;
                }

                return this.valueToCompare.indexOf(this.value) !== -1;
            case 'CONTAINS':
                if (this.valueToCompare === '*') {
                    return this.value.length > 0;
                }

                return this.value.indexOf(this.valueToCompare) !== -1;

            case 'NOTCONTAINS':
                if (this.valueToCompare === '*') {
                    return this.value.length === 0;
                }

                return this.value.indexOf(this.valueToCompare) === -1;
            case 'NIN':

                if (this.valueToCompare === '*') {
                    return this.value.length === 0;
                }

                return this.valueToCompare.indexOf(this.value) === -1;

            case 'IS':
            case 'EQ':
                if (this.valueToCompare === '*') {
                    return this.value !== undefined && this.value !== null;
                }

                return this.value == this.valueToCompare;

            case 'ISN':
            case 'NEQ':
                if (this.valueToCompare === '*') {
                    return this.value === undefined || this.value === null;
                }

                return this.value != this.valueToCompare;
        }
    }
}