Vue.asyncComponent('ak-header-contextual-menu', {
    data() {
        return {
            localViewMode: null,
            headerContextualWidgetOpen: false,
        }
    },
    props: {
        viewMode: {
            type: String,
            required: false,
        },
    },
    watch: {
        localViewMode() {
            this.$emit('view-mode-changed', this.localViewMode);
        }
    },
    methods: {
        /**
         * Set view mode
         * We can toggle to a compact view mode or a default view mode
         * @param mode
         */
        setViewMode(mode) {
            this.headerContextualWidgetOpen = false;
            this.localViewMode = mode;
            localStorage.setItem('ak-data-table-view-mode', this.localViewMode);
        }
    },
    mounted() {
        // set the current viewMode
        this.localViewMode = localStorage.getItem('ak-data-table-view-mode');
    }
}, 'grid/layout/ak-header-contextual-menu.html');