import {getSetting} from "./settings";

class LocaleHandler {
    /**
     * Construct
     */
    constructor() {
        this.setActiveLocale(this.getDefaultLocale());
    }

    /**
     * Set the active locale
     * @param activeLocale
     */
    setActiveLocale(activeLocale) {
        this.activeLocale = activeLocale;
    }

    /**
     * Get the active locale
     * @returns {*}
     */
    getActiveLocale() {
        return this.activeLocale;
    }

    /**
     * Get the default locale
     * @returns {*}
     */
    getDefaultLocale() {
        return getSetting('defaultLocale');
    }

    /**
     * Get all the available locales
     * @returns {*}
     */
    getLocales() {
        return getSetting('locales');
    }
}

export default {
    install: (Vue) => {
        Vue.prototype.$localeHandler = Vue.observable(new LocaleHandler());
    }
};