import FormElementMixin from '../mixins/FormElementMixin.js';
import MatrixFormTrigger from "../triggers/MatrixFormTrigger";

Vue.asyncComponent('ak-matrix', {
    mixins: [FormElementMixin],
    data() {
        return {
            currentValues: [],
            refreshWidgetIndex: 0
        }
    },
    props: {
        controls: {
            type: Array,
            required: true,
        },
        controlErrors: {
            type: Array|Object,
        },
        sortingEnabled: {
            required: false,
        },
        addLineEnabled: {
            required: false,
            default: true,
        },
        deleteLineEnabled: {
            required: false,
            default: true,
        },
        groupsEnabled: {
            required: false,
            default: false,
        },
        groupControl: {
            type: Object,
            required: false,
        },
        widths: {
            type: Array,
            required: false
        }
    },
    watch: {
        currentValues() {
            let values = [...this.currentValues];
            if(this.value !== values) {
                this.currentValue = values;
            }
        }
    },
    computed: {
        widthsCssVariable() {
            if ( ! this.widths) {
                return null;
            }
            return '--matrix-grid-auto-columns:' + JSON.parse(this.widths).join(' ') + ';';
        }
    },
    methods: {
        /**
         * Add a new row to the matrix
         */
        addRow() {
            this.addValueObject();
        },
        /**
         * Add a new row to given group
         */
        addRowToGroup(group) {
            group.items.push(this.getValueObject());
        },
        /**
         * Remove an item from the values by key
         * @param key
         */
        removeRow(key) {
            this.currentValues.splice(key, 1);
        },
        /**
         * Remove an item from the group
         * @param key
         */
        removeRowFromGroup(groupkey,key) {
            this.currentValues[groupkey].items.splice(key, 1);
        },
        /**
         * Remove an group
         * @param key
         */
        removeGroup(groupkey) {
            this.currentValues.splice(groupkey, 1);
        },
        /**
         * We push an extra value row
         */
        addValueObject() {
            this.$nextTick(() => {
                this.currentValues.push(this.getValueObject());
            });
        },
        /**
         * generate the value object depending on the controls given
         * @returns {{}}
         */
        getValueObject() {
            let obj = {
                valueId: Date.now()
            };

            this.controls.forEach(control => {
                // we use the value fo the given formControl in case we have a default value set
                obj[control.id] = JSON.parse(JSON.stringify(control.formControl.value)); // make sure we clone the value
            });

            return obj;
        },
        /**
         * We push an extra group to the value
         */
        addGroupObject() {
            this.$nextTick(() => {
                this.currentValues.push(this.getGroupValue());
            });
        },
        /**
         * Generate a new group object
         * @returns {{valueId: number, items: *[]}}
         */
        getGroupValue() {
            const group = {
                valueId: Date.now(),
                items: []
            };

            if (this.groupControl) {
                group[this.groupControl.id] = null
            }

            group.items.push(this.getValueObject());
            return group;
        },
        /**
         * Generate the widget attributes for the matrix columns
         * @param widget
         * @param row
         * @returns {*&{id, title: null}}
         */
        widgetAttributes(widget, row, group) {
            const currentValue = group !== undefined ?
                this.currentValues[group]['items'][row][widget.id]:
                this.currentValues[row][widget.id];

            let attributes = {
                id: widget.id,
                ...widget.formControl,
                ...widget.attributes,
                value: currentValue
            };

            if (this.groupsEnabled && !attributes.placeholder) {
                attributes.placeholder = attributes.title;
            }

            attributes.title = null;

            if (! this.controlErrors) {
                return attributes;
            }

            if (this.groupsEnabled && this.controlErrors[group] && this.controlErrors[group] && this.controlErrors[group][row] && this.controlErrors[group][row][widget.id]) {
                attributes = {...attributes, ...this.controlErrors[group][row][widget.id]};
                return attributes;
            }

            if (this.controlErrors[row] && this.controlErrors[row][widget.id]) {
                attributes = {...attributes, ...this.controlErrors[row][widget.id]};
                return attributes;
            }

            return attributes;
        },
        /**
         * Validate the child widgets
         * @param $event
         * @param widgetId
         * @param rowId
         * @param groupId
         */
        validateChildWidget($event, widgetId, rowId, groupId = null) {
            this.$emit('blur', new MatrixFormTrigger(widgetId, rowId, groupId))
        },
        /**
         * Update the refresh widget index so the widgets will be forced to refresh
         */
        triggerRefreshWidget() {
            this.refreshWidgetIndex++;
        }
    },
    created() {
        if ( this.value && this.value.length) {
            // if we have values we assume it is in the correct format
            this.currentValues = this.value;
        } else {
            // we add a value object for 1 row because default we show 1 row
            if( this.groupsEnabled) {
                this.addGroupObject();
            } else {
                this.addValueObject();
            }
        }
    }
}, 'form/controls/ak-matrix.html');
