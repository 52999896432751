import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-input-text', {
	mixins: [FormElementMixin],
	props: {
		type: {
			type: String,
			default: 'text'
		},
		icon: {
			type: String,
		},
		showClear: {
			type: Boolean,
		},
		tabIndex: {
			required: false,
		},
		maxlength: {
			type: Number,
		}
	},
	methods: {
		triggerClear() {
			this.currentValue = "";
			this.$emit('clear', "");
		},
		triggerSubmit() {
			this.$emit('submit');
		}
	}
}, 'form/controls/ak-input-text.html');
