import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-radio', {
    mixins: [FormElementMixin],
    props: {
        selectOptions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selected: null
        }
    },
    watch: {
        selected() {
            this.currentValue = this.selected;
        }
    },
    created() {
        this.selected = this.value;
    }
}, 'form/controls/ak-radio.html');