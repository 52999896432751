Vue.asyncComponent('ak-confirm', {
    name: 'AkConfirm',
    props: {
        show: Boolean,
        confirmTitle: {
            type: String
        },
        confirmText: {
            type: String,
        },
        confirmLabel: {
            type: String
        },
        cancelLabel: {
            type: String
        },
    },
    model: {
        prop: 'show',
        event: 'change'
    },
    computed: {
        showModal: {
            get() {
                return this.show === true;
            },
            set(val) {
                this.$emit('change', val);
            }
        }
    },
    methods: {
        confirmed() {
            this.showModal = false;
            this.$emit('confirm');
        },
        canceled() {
            this.showModal = false;
            this.$emit('cancel');
        }
    }
}, 'layout/ak-confirm.html');