export default class GlobalConfirm {
    constructor() {
        this.confirmTitle = null;
        this.confirmText = null;
        this.confirmLabel = null;
        this.cancelLabel = null;
        this.confirmEvent = null;
        this.cancelEvent = null;
        this.eventData = [];
        this.baseComponent = null;
        this.show = null;
    }

    /**
     * @param  show
     */
    setShow(show) {
        this.show = show;
    }

    /**
     * @param confirmTitle
     */
    setConfirmTitle(confirmTitle) {
        this.confirmTitle = confirmTitle;
    }

    /**
     * @param confirmText
     */
    setConfirmText(confirmText) {
        this.confirmText = confirmText;
    }

    /**
     * @param confirmText
     */
    setConfirmLabel(confirmLabel) {
        this.confirmLabel = confirmLabel;
    }

    /**
     * @param cancelLabel
     */
    setCancelLabel(cancelLabel) {
        this.cancelLabel = cancelLabel;
    }

    /**
     * @param confirmEvent
     */
    setConfirmEvent(confirmEvent) {
        this.confirmEvent = confirmEvent;
    }

    /**
     * @param cancelEvent
     */
    setCancelEvent(cancelEvent) {
        this.cancelEvent = cancelEvent;
    }

    /**
     * @param eventData
     */
    setEventData(eventData) {
        this.eventData = eventData
    }

    /**
     * Set the component on wich we need to trigger the confirm and cancel events
     * @param component
     */
    setBaseComponent(component) {
        this.baseComponent = component;
    }

    /**
     * We trigger the confirm event on the base component
     * So the backend can also reaction to the confirm via a widget callback
     */
    triggerConfirmEvent() {
        this.baseComponent.$emit(this.confirmEvent, this.eventData);
    }

    /**
     * We trigger the cancel event on the base component
     * So the backend can also reaction to the cancel via a widget callback
     */
    triggerCancelEvent() {
        this.baseComponent.$emit(this.cancelEvent, this.eventData);
    }
}