Vue.asyncComponent('ak-contextual-widgets', {
    data() {
        return {
            show: false
        }
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        widgets: {
            type: Object,
            required: true,
            default: () => {}
        },
        rowId: {
            type: String,
            required: true
        },
        callbackUri: {
            type: String,
            required: false,
            default: () => window.location.toString()
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        },
        /**
         * Get the listeners we want to pass to the widgets
         *
         * @returns {*}
         */
        listenersToPass() {
            const tempListeners = {...this.$listeners};

            // We remove the listeners that we already handle
            delete tempListeners['edit'];
            delete tempListeners['delete'];
            delete tempListeners['refresh'];

            return tempListeners
        }
    },
    methods: {
        handleEdit(data) {
            this.show = false;
            this.$emit('edit', data);
        },
        handleDelete(data) {
            this.show = false;
            this.$emit('delete', data);
        },
    }
}, 'grid/layout/ak-contextual-widgets.html');
