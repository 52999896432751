import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-widget-behind-icon', {
    mixins: [FormElementMixin],
    data() {
        return {
            currentOpen: false
        };
    },
    props: {
        open: {
            type: Boolean,
            required: false
        },
        icon: {
            type: String,
            required: true
        },
        widget: {
            type: Object,
            required: true
        },
        direction: {
            type: String,
            default: 'left',
            required: false
        },
        tooltip: {
            type: String,
            required: false
        }
    },
    methods: {
        toggleMenu() {
            this.currentOpen = !this.currentOpen;
            this.$emit('change', this.currentOpen);
        },
        closeMenu() {
            this.currentOpen = false;
            this.$emit('change', this.currentOpen);
        }
    },
    created() {
        this.currentOpen = this.open;
    }
}, 'form/controls/ak-widget-behind-icon.html');
