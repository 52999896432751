Vue.asyncComponent('ak-text', {
    props: {
        label: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: true,
        },
    },
}, 'layout/ak-text.html');