Vue.asyncComponent('ak-stacked-window', {
	name: 'ak-stacked-window',
	data() {
		return {
			show: false,
			currentWidth: this.width,
			layerDepth: 0,
			startX: null,
			startWidth: null,
		}
	},
	props: {
		closable: {
			type: Boolean,
			default: true
		},
		showClose: {
			type: Boolean,
			default: true
		},
		width: {
			type: String,
		},
		minWidth: {
			type: String,
		},
		resizable: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		width() {
			if (this.currentWidth !== this.width) {
				this.setWidth(this.width);
			}
		},
		minWidth() {
			if(! this.resizable || this.resizable && !this.width) {
				this.currentWidth = this.minWidth;
			}
		}
	},
	computed: {
		depthClass() {
			return "ak-stacked-window--depth-" + this.layerDepth;
		}
	},
	methods: {
		setLayerDepth(layerDepth = 0) {
			let parent = this.$parent;
			while (parent) {
				parent = parent.$parent;

				if (parent && parent.$options.name == this.$options.name) {
					layerDepth++;
					
					if (layerDepth > 3) layerDepth = 3;

					parent.layerDepth = layerDepth;
				}
			}
		},
		close() {
			this.setLayerDepth(-1);
			this.$emit('close');
		},
		attemptClose() {
			// Check if this is closable
			if (!this.closable) return;
			// Check if there is an attemptClose listener bound
			if (this.$listeners.attemptClose) this.$emit('attemptClose', this.close);
			else if (this.closable) {
				// if no attemptClose listener is bound, just close
				this.close();
			}
		},
		amountOfChildStackedWindows() {
			let children = this.$children;
			let amountOfChildStackedWindows = 0;

			while (children) {
				let childChildren = [];

				children.forEach((child) => {
					if (child.$options.name == this.$options.name) {
						amountOfChildStackedWindows++;
					}
					if(child.$children) {
						childChildren = [...childChildren, ...child.$children];
					}
				});

				if(childChildren.length) {
					children = childChildren;
				} else {
					children = null;
				}
			}

			return amountOfChildStackedWindows;
		},
		/**
		 * Start resizing set the needed vars so we can calculate the width & offset
		 * @param e
		 */
		startResize(e) {
			this.resizing = true;
			this.startX = e.clientX;
			this.startWidth = parseInt(this.$refs.resizable.offsetWidth, 10);
			document.addEventListener('mousemove', this.resize);
			document.addEventListener('mouseup', this.stopResize);
		},
		/**
		 * Calculate the width when dragging
		 * @param e
		 */
		resize(e) {
			let width = (this.startWidth - e.clientX + this.startX);

			this.setWidth(width);
		},
		/**
		 * Set the curren width
		 *
		 * @param width
		 */
		setWidth(width) {
			// Width can not be bigger then 80% of the screen size
			if (width > window.innerWidth) {
				width = window.innerWidth;
			}

			this.currentWidth = width  + 'px'
			this.$emit('resize', width  + 'px');
		},
		/**
		 * remove the drag event listeners
		 */
		stopResize() {
			this.resizing = false;
			document.removeEventListener('mousemove', this.resize);
		},
		/**
		 * Close stacked window when we pres ESC
		 * @param e
		 */
		escapeHandler(e) {
			if (e.key === 'Escape') {
				this.attemptClose();
			}
		}
	},
	created() {
		// fix for multiple nexted windows
		this.$nextTick(() => {
			this.show = true;
		});
		this.setLayerDepth();
		this.$emit('open');
	},
	mounted: function() {
		this.$root.$el.append(this.$el);
		document.addEventListener('keydown', this.escapeHandler);
	},
	destroyed: function() {
		document.removeEventListener('keydown', this.escapeHandler);
		document.removeEventListener('mousemove', this.resize);
		document.removeEventListener('mouseup', this.stopResize);
		if(this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
	}
}, 'utilities/ak-stacked-window.html');
