import moment from 'moment';

export function nl2br(value) {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function toDate(value) {
    if (isNaN(new Date(value).getTime())) return '';
    return moment(value).format(Vue.prototype.$getSetting('config.dateFormat'));
}

export function toDateTime(value) {
    if (isNaN(new Date(value).getTime())) return '';
    return moment(value).format(Vue.prototype.$getSetting('config.dateTimeFormat'));
}

export function toDateRelative (value) {
    if (isNaN(new Date(value).getTime())) return '';
    if (Math.abs(moment().diff(value)) < 86400000) { // 1 day
        return moment(value).fromNow();
    }
    return moment(value).format(Vue.prototype.$getSetting('config.dateTimeFormat'));
}

export function snippet (value, options) {
    let tempValue = value.replace(/<[^>]*>?/gm, '');

    const delimiter = tempValue.length > options.limit ? options.endDelimiter : '';

    return tempValue.substring(0, options.limit) + delimiter;
}

export function toCurrency (value, options) {
    let amount = Number.parseInt(value).toFixed(options[0] || 2);
    amount = amount.replace('.', ',');

    if (options[1]) {
        amount = `${amount} ${options[1]}`;
    } else {
        amount = `€${amount}`;
    }

    return amount;
}

export function toNumber (value, options = []) {
    if (! value) {
        return '';
    }

    value = value.toString();

    // If negative, remove negative symbol to correctly format number
    let isNegative = false;
    if (parseFloat(value) < 0) {
        isNegative = true;
        value = value.replace('-', '');
    }

    let decimalSeparator = ",";
    let thousandSeparator = ".";

    // split the number in the integer and decimals, if any
    let parts = value.split('.');
    let precision = "precision" in options ? options["precision"] : 0;

    // if we don't have decimals, add .00
    if ( ! parts[1]) {
        parts[1] = '';
        for (let i = 0; i < parseInt(precision); i++) {
            parts[1] += '0';
        }
    }
    else {
        parts[1] = parts[1].substr(0, parseInt(precision));
    }

    // reverse the string (1719 becomes 9171)
    let result = parts[0].split("").reverse().join("");

    // Add thousand separator each 3 characters, except at the end of the string
    result = result.replace(/(\d{3}(?!$))/g, "$1" + thousandSeparator);

    // reverse back the integer and replace the original integer
    parts[0] = result.split("").reverse().join("");

    // recombine integer with decimals
    let resultValue = !parts[1]
            ? parts[0]
            : parts.join(decimalSeparator);

    // If negative, add negative symbol again
    if (isNegative) {
        resultValue = '-' + resultValue;
    }

    return resultValue;
}

export function toValueLabel (value, options) {
    return options[value];
}

export function toHumanFileSize(bytes, si= true, dp= 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

// Replace new lines with <br> tags
Vue.filter('nl2br', nl2br);

Vue.filter('date', toDate);

Vue.filter('custom-date', (value, options) => {
    if (isNaN(new Date(value).getTime())) return '';
    return moment(value).format(options.format);
});

Vue.filter('datetime', toDateTime);
Vue.filter('dateTime', toDateTime);

Vue.filter('date-relative', toDateRelative);
Vue.filter('dateRelative', toDateRelative);

Vue.filter('snippet', snippet);

Vue.filter('string', (value) => {
    return value;   
});

Vue.filter('currency', toCurrency);

Vue.filter('bool', (value, options = []) => {
    return value;
});

Vue.filter('number', toNumber);

Vue.filter('value-label', toValueLabel);
Vue.filter('valueLabel', toValueLabel);

Vue.filter('fileSize', toHumanFileSize);
Vue.filter('file-size', toHumanFileSize);

const doFormat = (value, format) => {
    return Vue.options.filters[format.format](value,format.args);
}

export const applyFormatting = (placeholder, placeholderFormatting) => {
    // check if we have an array of placeholders
    if(! placeholder) return placeholder;
    
    if(typeof placeholder === "object") {
        let formatPlaceholders = {};
        Object.entries(placeholder).forEach(([key, value]) => {
            let format = placeholderFormatting[key];
            
            formatPlaceholders[key] = doFormat(value,format)
        })
        
        return formatPlaceholders;
    }
    
    // if not just format
    return doFormat(placeholder, placeholderFormatting)
}

export const formatPlugin = {
    install: (Vue) => {
        Vue.prototype.$applyFormatting = (placeholders, placeholdersFormatting) => applyFormatting(placeholders, placeholdersFormatting);
    }
}