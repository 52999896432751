Vue.asyncComponent('ak-draggable-modal', {
    data() {
        return {
            startMouseOffsetX: null,
            startMouseOffsetY: null,
            posX: null,
            posY: null,
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
        },
        overlay: {
            type: Boolean,
            default: true
        },
        overlayOpacity: {
            type: Number,
        },
        width: {
            type: Number|String,
        },
        size: {
            type: String,
        },
        attach: {
            type: HTMLElement,
            default: () => {
                return document.querySelector('body')
            }
        }
    },
    model: {
        prop: 'show',
        event: 'change'
    },
    computed: {
        /**
         * We return the style for the modal
         * @returns {*}
         */
        modalStyle() {
            let style = {transform: ''};

            // Check if the width is a number
            // if so we add px to it
            if(this.width && typeof this.width === 'number') {
                style['width'] = `${this.width}px`;
            } else if (this.width) {
                style['width'] = this.width;
            }

            // if we have a posX we will set it else we will center the element
            if (this.posX) {
                style['left'] = this.posX + 'px';
            } else {
                style['left'] = '50%';
                style['transform'] = style['transform'] + ' translateX(-50%)';
            }

            // if we have a posY we will set it else we will center the element
            if (this.posY) {
                style['top'] = this.posY + 'px';
            } else {
                style['top'] = '50%';
                style['transform'] = style['transform'] + ' translateY(-50%)';
            }

            return style;
        },
        /**
         * Wer return the style for the overlay
         * @returns {{}}
         */
        overlayStyle() {
            let style = {};

            if (this.overlayOpacity) {
                style['--overlay-opacity'] = this.overlayOpacity;
            }

            return style;
        }
    },
    methods: {
        /**
         * Close the model
         */
        close() {
            this.$emit('close');
            this.$emit('change', false);
        },
        /**
         * We start tracking the mouse position to move the modal arround
         */
        startDragging(e) {
            this.startMouseOffsetX = e.clientX - this.$refs.modal.getBoundingClientRect().x;
            this.startMouseOffsetY = e.clientY - this.$refs.modal.getBoundingClientRect().y;
            document.addEventListener('mousemove', this.changePosition);
            document.addEventListener('mouseup', this.stopDragging);
        },
        /**
         * We stop tracking the mouse position to move the modal arround
         */
        stopDragging() {
            document.removeEventListener('mousemove', this.changePosition);
        },
        /**
         * We set the modal position to the position of the mouse
         * @param e
         */
        changePosition(e) {
            // We calculate the wanted position
            // we need to take the offset of the drag handle in to account
            this.posX = e.clientX - this.startMouseOffsetX;
            this.posY = e.clientY - this.startMouseOffsetY;
        },
    },
    mounted() {
        // Move the element to the defined attach element
        this.attach.appendChild(this.$el);
    }
}, 'layout/ak-draggable-modal.html');