
function getSecondsAsParts(seconds) {
    var counts = {
        'years'   : 31556926,
        'months'  : 2629743.83,
        'weeks'   : 604800,
        'days'    : 86400,
        'hours'   : 3600,
        'minutes' : 60
    };

    var out = {};
    var i, secondsInPart;

    for (var part in counts) {
        secondsInPart = counts[part];
        if (seconds >= secondsInPart) {
            out[part] = Math.floor(seconds / secondsInPart);
            seconds -= out[part] * secondsInPart;
        }
    }

    if (seconds > 0) {
        //and at last the total amount of seconds
        out['seconds'] = Math.round(seconds);
    }

    return out;
}

Vue.asyncComponent('ak-export', {
    /*
     * Props
     */
    props : {
        bundle : {
            type : String,
            required : true
        },
        definition : {
            type : String,
            required : true,
            default : "export"
        },
        params : {
            type : Object,
        }
    },
    /*
     * Data
     */
    data : function () {
        return {
            // When the "created" hook has finished the kickoff asynchronous call,
            // it will set this flag to FALSE
            isInitializing : true,
            // Whenever the export is loading (waiting for a batch to be finished
            // by the server), this flag will be TRUE
            isLoading : false,
            // The user is able to press a play/pause button, which will affect the
            // following flag:
            isPaused : false,
            // When the export is completed, and the file is ready for download via
            // the doDownload() method, this flag will be set to TRUE.
            isReady : false,
            // Data returned by the AppKit Export on server
            db : {},
            // The sum of loading time, accumulated over all of the batches. This will
            // be used to calculate the average loading time per batch. That average
            // loading time allows us to forecast an ETA
            milliSecondsElapsed : 0,
            // Percentage completed
            percentage : 0,
            // Parameters, sent back to the AppKit Export on server. Parameters here
            // include the selected items in the AppKit List/Grid, the applied filters,
            // sorting, and so on...
            fetchParams : {
                // The batch (page number) to begin exporting at:
                batch : 1,
                // merge with the params that get passed as props
                ... this.params
            },
            // to cancel the fetch calls when the user closes or cancels the export we need to have an abortController and signal
            abortController: null,
            signal: null,
        };
    },
    /*
     * Computed data values
     */
    computed : {
        /*
         * 'progressBarColor': The template can rely on the component too, for the
         * color of the progress bar. Can be altered via the prop, and will be altered
         * to "success" when done!
         *
         * @return String
         */
        progressBarColor() {
            if (this.isReady) {
                return 'success';
            }
            if (this.isPaused) {
                return 'blue-grey';
            }
            return 'amber';
        },
        etaInSeconds() {
            // If we have not yet completed any batch, we cannot forecast anything
            if (this.milliSecondsElapsed == 0) {
                return;
            }

            // Calculate the avg loading time per batch
            var avgMilliseconds = this.milliSecondsElapsed / this.fetchParams.batch;

            // Calculate the remaining number of batches
            var remainingBatches = (this.db.batchCount - this.fetchParams.batch - 1);

            // Multiply by the avg loading time, and return:
            return ((remainingBatches * avgMilliseconds) / 1000);
        },
        etaInParts() {
            // Get the ETA in seconds, and break it up into time parts:
            var parts = getSecondsAsParts(this.etaInSeconds);

            // Render natural language with that. Note that we limit ourselves to
            // English for the time being, by simply concatenating time parts and
            // values... Because the use of placeholders in translated texts is
            // not completed for now:
            var out = '';
            var i;
            for (i in parts) {
                if (out != '') {
                    out += ', ';
                }
                out += parts[i] + ' ' + i;
            }

            return out;
        }
    },
    /*
     * Methods for the Component
     */
    methods : {
        /**
         * Do Batch
         */
        doBatch: async function () {
            // Set the "Is Loading" flag
            this.isLoading = true;

            // create an inspector variable so we can get the time the call takes
            let inspector = {};

            // Send off the request for the current batch. When we get back the response
            // from the AppKit Export on the server...
            this.db = await this.$get(this._getRelativePath('batch'), this.fetchParams, this.signal, inspector);

            // if we dont have a response the operation is canceled.
            if(! this.db) {
                // then we stop here
                return;
            }

            // ... Add loading time to total of elapsed time:
            this.milliSecondsElapsed += inspector.milliseconds;

            // ... Move cursor to next batch:
            this.fetchParams.batch++;

            // ... Set "Is Loading" back to FALSE:
            this.isLoading = false;

            // ... Update the "Is Ready" flag, with the isEof flag returned
            //     by the AppKit Export on the server:
            this.isReady = this.db.batch.isEof;

            if (this.isReady) {
                this.$emit('ready');
            }

            // ... Update the percentage completed, for the progress bar shown (if any)
            this.percentage = this.db.totalCount == 0 ? 100 : Math.floor((this.db.batch.end / this.db.totalCount) * 100);

            // ... If not ready yet, and not paused, continue to next batch:
            if (!this.isReady && !this.isPaused) {
                this.doBatch();
            }
        },
        /*
         * Do Download
         */
         doDownload() {
             this.$emit('downloaded');

            // Redirect the user to the AppKit Export's Download Endpoint
            window.location.href = this.$generateUrl(this._getRelativePath('download'),
                    this.fetchParams
            );
        },
        /*
         * Toggle Play/Pause Button
         */
        doPause() {
            this.isPaused = true;
        },
        doPlay() {
            this.isPaused = false;
            if (! this.isLoading) {
                this.doBatch();
            }
        },
        /*
         * Get Relative path to AppKit Export on server.
         *
         * @param String slug
         * @return String
         */
        _getRelativePath(slug) {
            return `/${this.bundle}/${this.definition}/${slug}`;
        }
    },
    /*
     * "Created" Hook
     */
    async created() {
        // link the abort controller so we can cancel all fetch calls when the component is destoryed
        this.abortController = new AbortController();
        // link the signal of the abortController so we can pass it as a param to the fetch request
        this.signal = this.abortController.signal;

        // When creating this component, we will request the "kickoff" for the AppKit
        // Export, on the server. That kickoff will provide us with information, such
        // as the total count of items, the title of the Export Definition, and so on...
        this.db = await this.$get( this._getRelativePath('kickoff'), this.fetchParams, this.signal);

        // ... Set "initializing" flag to FALSE
        this.isInitializing = false;

        // ... And, set path to export file in the parameters. Subsequent calls
        //     to the AppKit Export on the server, will require this parameter
        //     in the URL:
        this.fetchParams.path = this.db.path;

        // ... Go ahead and start with the first batch of the export:
        this.doBatch();
    },
    /*
     * "Before destroy" Hook
     */
    beforeDestroy() {
        // when the component is destroy abort all fetch calls
        this.abortController.abort();
    },
}, 'grid/ak-export.html');