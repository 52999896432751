Vue.asyncComponent('ak-tabs', {
    data() {
        return {
            tabsOverflowingLeft: false,
            tabsOverflowingRight: false
        }
    },
    methods: {
        /**
         * Check the tabs navigation overflow state
         */
        checkTabsOverflow() {
            this.tabsOverflowingLeft = false;
            this.tabsOverflowingRight = false;

            if (! this.$refs.tabsNavigation) {
                return;
            }

            const navigation = this.$refs.tabsNavigation;

            // check if there is horizontal overflow
            if (navigation.offsetWidth >= navigation.scrollWidth) {
                return;
            }

            // check if we have overflow right
            if ((navigation.offsetWidth + navigation.scrollLeft) < navigation.scrollWidth) {
                this.tabsOverflowingRight = true;
            }

            // if we have overflow left
            if (navigation.scrollLeft > 0) {
                this.tabsOverflowingLeft = true;
            }
        },
        /**
         * Scroll tab navigation to right
         */
        scrollTabsLeft() {
            const navigation = this.$refs.tabsNavigation;
            const scrollLeft = this.$refs.tabsNavigation.scrollLeft + Math.round(this.$refs.tabsWrapper.offsetWidth / 2);

            for (const childKey in navigation.children) {
                const child = navigation.children[childKey];

                if(scrollLeft <= (child.offsetLeft + Math.round(child.offsetWidth / 2))) {
                    const prevChild = navigation.children[childKey - 1];
                    const leftOverToScroll = (prevChild.offsetLeft + Math.round(prevChild.offsetWidth / 2) - scrollLeft);

                    this.$refs.tabsNavigation.scrollTo({
                        left: this.$refs.tabsNavigation.scrollLeft + leftOverToScroll,
                        behavior: 'smooth'
                    });
                    return;
                }
            }
        },
        /**
         * Scroll tab navigation to left
         */
        scrollTabsRight() {
            const navigation = this.$refs.tabsNavigation;
            const scrollLeft = this.$refs.tabsNavigation.scrollLeft + Math.round(this.$refs.tabsWrapper.offsetWidth / 2);

            for (const childKey in navigation.children) {
                const child = navigation.children[childKey];

                if(scrollLeft < (child.offsetLeft + Math.round(child.offsetWidth / 2))) {
                    const leftOverToScroll = (child.offsetLeft + Math.round(child.offsetWidth / 2) - scrollLeft);

                    this.$refs.tabsNavigation.scrollTo({
                        left: this.$refs.tabsNavigation.scrollLeft + leftOverToScroll,
                        behavior: 'smooth'
                    });
                    return;
                }
            }
        }
    },
    mounted() {
        this.checkTabsOverflow();
        document.addEventListener('resize', this.checkTabsOverflow);
        this.$refs.tabsNavigation.addEventListener('scroll', this.checkTabsOverflow);
    },
    beforeUnmount() {
        document.removeEventListener('resize', this.checkTabsOverflow);
        this.$refs.tabsNavigation.removeEventListener('scroll', this.checkTabsOverflow);
    }
}, 'layout/ak-tabs.html');