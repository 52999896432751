Vue.asyncComponent('ak-data-list', {
    data() {
        return {
            idSelected: this.selection.idsSelected[0],
            showModal: false,
            showContextualWidgets: false,
            contextualWidgets: {
                list: []
            },
            objectIds: [],
            isOverflowing: false,
            snackbarMessage: '',
            autoSelection: false,
            dragId: null,
            showDragContext: false,
            dragContextPosition: {
                x: 0,
                y: 0
            },
            dragContextTimeout: null,
            dragging: null,
            activeRowId: '',
            activeSelectAll: false,
            isMobileView: false,
        }
    },
    props: {
        dataObjects: {
            type: Array,
            required: true,
            default: () => {}
        },
        selectables: {
            type: Object,
            required: true,
            default: () => {}
        },
        selection: {
            type: Object,
            required: true,
            default: () => {}
        },
        isUpdating: {
            type: Boolean,
            required: false,
            default: false
        },
        sortingEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        pageJumper: {
            type: Object,
            required: false,
            default: () => {}
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        maxTableHeight: {
            type: Number,
            required: false
        },
        numberOfTilesPerRow: {
            type: Number,
            required: false
        },
        noResultString: {
            type: String,
            required: false,
            default: 'Geen resultaten gevonden'
        },
        initialized: {
            type: Boolean,
            required: false,
            default: false
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        mutableDataObjects: {
            get() {
                return this.dataObjects;
            },
            set(e) {
                this.$emit('changeList', e);
            }
        },
        totalColumnCount() {
            let columnCount = this.columns.length

            if(this.sortingEnabled) {
                columnCount++;
            }

            if(this.selectables.canSelectAll || this.selectables.canSelectOneByOne) {
                columnCount++;
            }

            if(this.widgetsInRow) {
                columnCount++;
            }

            return columnCount;
        },
        dragContextOptions() {
            const index = this.dataObjects.findIndex(x => x.id === this.dragId);

            if (this.dataObjects.length === 1 || index === -1) return {};
            let options = null;

            switch (index) {
                case 0 && this.pageJumper.activePage === 1:
                    options = [
                        { title: 'To bottom', value: 'bottom' }
                    ];
                    break;
                case this.dataObjects.length - 1: case this.pageJumper.activePage === this.pageJumper.numberOfPages:
                    options = [
                        { title: 'To top', value: 'top' }
                    ];
                    break;
                default:
                    options = [
                        { title: 'To top', value: 'top' },
                        { title: 'To bottom', value: 'bottom' }
                    ];
                    break;
            }

            if (!this.$getSetting('config.infiniteScroll') && this.pageJumper.activePage !== 1 && index === 0) {
                options.splice(1, 0, { title: 'Up 1', value: 'up'} );
            }
            if (index === this.pageJumper.countPerPage - 1 && this.pageJumper.activePage !== this.pageJumper.numberOfPages) {
                options.splice(1, 0, { title: 'Down 1', value: 'down' });
            }

            return options;
        },
        selectModalContent() {
            let string = this.$t(`Wil je alle items selecteren of enkel de ingeladen items?`);
            if( this.pageJumper.totalCount > this.selectables.maximum) {
                string += (this.selectables.maximum ? this.$t(`Je mag er maximum @max) selecteren.`, {'@max': this.selectables.maximum}) : '')
            }

            return string;
        }
    },
    methods: {
        showSelectAllModal() {
            if (this.selection.allSelected) {
                this.selectAll();
            }
            else if (this.autoSelection) {
                this.selection.idsSelected = [];
                this.autoSelection = false;
            }
            else {
                this.showModal = true;
            }
        },
        selectAll() {

            if(this.objectIds.length > this.selectables.maximum) {
                return this.selectMaximum();
            }

            this.selection.allSelected = !this.selection.allSelected;
            if (this.selection.allSelected && !this.autoSelection) this.selection.idsSelected = this.objectIds;
            else this.selection.idsSelected = [];
            this.closeModal();
        },
        closeModal() {
            this.showModal = false;

            if(!this.selection.allSelected && !this.autoSelection) {
                this.activeSelectAll = false;
            }
        },
        selectMaximum() {
            this.autoSelection = true;
            this.selection.idsSelected = this.objectIds.slice(0, this.selectables.maximum);
            this.closeModal();
        },
        isRowSortedBy(key) {
            return this.columns.find(x => x.id === key).isSortedBy;
        },
        findColumnTitle(key) {
            return this.columns.find(x => x.id === key).title;
        },
        applyColumnFormatting(key, value) {
            const formatting = this.columns.find(x => x.id === key).formatting;

            // if there is no formatting set in for this value just return the value
            if(typeof formatting == 'undefined') {
                return value;
            }

            // else we apply the formatting
            return this.$applyFormatting(value, formatting);
        },
        sortColumn(column) {
            if (this.isUpdating) return;
            this.$emit('sortColumn', column);
        },
        showContextualDialog(e, id) {
            this.contextualWidgets = e;
            this.activeRowId = id;
            this.showContextualWidgets = true;
        },
        closeContexualDialog() {
            this.showContextualWidgets = false;
            this.activeRowId = '';
        },
        handleEdit(data) {
            this.$emit('edit', data);
        },
        handleDelete(data) {
            if (this.isUpdating) return;
            this.closeContexualDialog();
            this.$emit('delete', data);
        },
        dragStart(e) {
            this.dragId = e.item.id;
            this.showDragContext = false;
            this.dragging = e.item.id;
            clearTimeout(this.dragContextTimeout);
        },
        dragEnd() {
            this.$emit('sort', { id: this.dragId, instruction: 'drag' });
            this.dragId = '';
            this.dragging = null;

        },
        draghandleClickContext(id, e) {
            this.dragContextTimeout = setTimeout(() => {
                this.dragId = id;
                this.dragContextPosition =  {
                    x: e.clientX,
                    y: e.clientY
                };
                this.showDragContext = true;
            }, 100);
        },
        changePosition(to) {
            this.showDragContext = false;

            this.$emit('sort', { id: this.dragId, instruction: to });
        },
        /**
         * Get the contextual title for a specififc row
         * @param rowId
         * @returns {null|*}
         */
        getContextualTitle(rowId) {
            if ( ! rowId) {
                return null;
            }

            return this.dataObjects.find(dataObject => dataObject.id === rowId).title;
        },
        triggerRefresh() {
            this.$emit('refresh');
        },
        /**
         * Set the selection for this row
         * @param rowId
         */
        toggleSelectRow(rowId) {
            if(this.selectables.maximum == 1) {
                if(this.idSelected === rowId) {
                    this.idSelected = null;
                } else {
                    this.idSelected = rowId
                }
            } else {
                if(this.selection.idsSelected.includes(rowId)) {
                    const index = this.selection.idsSelected.indexOf(rowId);
                    this.selection.idsSelected.splice(index, 1);
                } else {
                    this.selection.idsSelected.push(rowId);
                }
            }
        },
        /**
         * Remove the selection for this row
         * @param rowId
         */
        deselectRow(rowId) {
            if(this.selectables.maximum == 1) {
                this.idSelected = null;
            } else {
                const index = this.selection.idsSelected.indexOf(rowId);
                this.selection.idsSelected.splice(index, 1);
            }
        }
    },
    watch: {
        dataObjects(e) {
            this.objectIds = this.dataObjects.filter(x => x.canSelect === true).map(x => x.id);
            if (this.selection.allSelected) this.selection.idsSelected = this.objectIds;
        },
        'selection.idsSelected': {
            handler(e) {
                if (e.length === this.selectables.maximum + 1) {
                    this.$nextTick(()=> {
                        e.pop();
                    });
                    this.snackbarMessage = this.$t(`Maximaal @max tegelijk selecteerbaar.`, {'@max': this.selectables.maximum});
                }

            }
        },
        idSelected() {
            // if we the maximum selection is 1 we use radio buttons and we sync them with the selection.idsSelected
            if(! this.idSelected) {
                this.selection.idsSelected = [];
            } else {
                this.selection.idsSelected = [this.idSelected];
            }
        },
        showDragContext(e) {
            if (!e) this.dragId = null;
        }
    }
}, 'grid/ak-data-list.html');