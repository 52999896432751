Vue.asyncComponent('ak-confirm-unsaved-changes', {
    data() {
        return {
            ignoreConfirmCheck: false,
            showConfirm: false,
            confirmMessage: null,
            confirmRoute: null,
        }
    },
    methods: {
        /**
         * Check if we can do a location change
         * @param event
         * @returns {Promise<void>}
         */
        async checkConfirmLocationChange(event) {
            const result = await this.checkShowConfirm();

            // For a route change we need to use de default dialog so we show the message there
            if (result) {
                event.preventDefault();
                event.returnValue = this.$confirmUnsavedChangesHandler.getConfirmMessage();
            }
        },
        /**
         * Check if we can do a route change
         * @param to
         * @param from
         * @param next
         * @returns {Promise<void>}
         */
        async checkConfirmRouteChange(to, from, next) {
            this.confirmRoute = to;
            this.showConfirm = await this.checkShowConfirm(to,from,next);

            if(this.showConfirm) {
                this.confirmMessage = this.$confirmUnsavedChangesHandler.getConfirmMessage();
                next(false);
            } else {
                next();
            }
        },
        /**
         * Check confirm handler
         * Before each route change we check if we have a showConfirmCallback
         * If so we run it
         * If we need to show cofirmation we prevent a route change and only apply it if the user consensts to it
         * @param to
         * @param from
         * @param next
         * @returns {Promise<void>}
         */
        async checkShowConfirm(to, from, next) {
            // Check if we need to ignore this check
            // this is necassary for the confirmDialog action to work
            if(this.ignoreConfirmCheck) {
                this.ignoreConfirmCheck = false;
                return false;
            }

            // Check if we have callback
            const callback = this.$confirmUnsavedChangesHandler.getShowConfirmCallback();
            if(! callback) {
                return false;
            }

            // check if we need to show the confirm modal
            return await callback(to,from,next);
        },
        /**
         * Confirm route change
         */
        confirmRouteChange() {
            // make sure we ignore the check
            this.ignoreConfirmCheck = true;
            // hide the modal
            this.showConfirm = false;

            // reset the confirm handler
            this.$confirmUnsavedChangesHandler.setShowConfirmCallback(null);
            this.$confirmUnsavedChangesHandler.setConfirmMessage(null);

            // go to the original route
            this.$router.push(this.confirmRoute);
        }
    },
    created() {
        this.$router.beforeResolve(this.checkConfirmRouteChange)
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.checkConfirmLocationChange)
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.checkConfirmLocationChange);
    }
}, 'utilities/ak-confirm-unsaved-changes.html');