Vue.asyncComponent('ak-export-dialog', {
    /*
     * Props
     */
    props : {
        bundle : {
            type : String,
            required : true
        },
        definition : {
            type : String,
            default : "export"
        },
        params : {
            type : Object,
        }
    },
    /**
     *  Watch
     */
    watch: {
        doExport(value, oldValue) {
            if(! value) {
                this.$emit('close');
            }
        }
    },
    /*
     * Data
     */
    data : function () {
        return {
            doExport: true,
        };
    }
}, 'grid/ak-export-dialog.html');