export default class ConfirmUnsavedChangesHandler {

    getShowConfirmCallback() {
        return this.showConfirmCallback;
    }

    getConfirmMessage() {
        return this.message;
    }

    setShowConfirmCallback(callback) {
        this.showConfirmCallback = callback;
    }

    setConfirmMessage(message) {
        this.message = message;
    }
}