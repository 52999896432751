Vue.asyncComponent('ak-grid-fab', {
    props: {
        widgets: {
            type: Object,
            required: true
        },
        callbackUri: {
            type: String,
            required: false,
            default: () => window.location.toString()
        }
    },
    data() {
        return {
            fab: false
        }
    },
    computed: {
        getWidgetCount() {
			if (!this.widgets.list) return;
            return this.widgets.list.length;
        }
    },
    methods: {
        handleNew(data) {
            this.$emit('new', data);
            // we need to close the fab list manualy else the widget event can't be thrown correctly on the child fab buttons
            this.fab = false;
        }
	}
}, 'grid/layout/ak-grid-fab.html');