Vue.asyncComponent('ak-widget-dialog', {
    props: {
        title: {
            type: String,
            required: false,
        },
        widgets: {
            type: Object,
            required: true,
            default: () => {}
        },
        showDialog: {
            type: Boolean,
            required: true,
            default: false
		},
		rowId: {
			type: String,
			required: true
		},
        callbackUri: {
            type: String,
            required: false,
            default: () => window.location.toString()
        }
    },
    computed: {
        show: {
            get() {
                return this.showDialog;
            },
            set() {
                this.$emit('close');
            }
        }
    },
    methods: {
        handleEdit(data) {
            this.show = false;
            this.$emit('edit', data);
        },
        handleDelete(data) {
            this.show = false;
            this.$emit('delete', data);
        },
    }
}, 'grid/layout/ak-widget-dialog.html');