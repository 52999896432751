Vue.asyncComponent('ak-chip', {
    props: {
        title: {
            type: String,
            default: '',
            required: false,
        },
        description: {
            type: String,
            default: '',
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            required: false,
        },
        showClear: {
            type: Boolean,
        }
    },
    methods: {
        clearClicked() {
            this.$emit('clear');
        },
        click() {
            this.$emit('click');
        }
    }
}, 'layout/ak-chip.html');