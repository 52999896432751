Vue.asyncComponent('ak-tooltip', {
    data() {
        return {
            persistentOpen: true,
            hover: false,
            scrollPosition: null,
            tooltipPosition: null,
        }
    },
    props: {
        body: {
            type: String,
        },
        position: {
            type: String,
            default: 'top'
        },
        disabled: {
            type: Boolean,
        },
        persistent: {
            type: Boolean,
        },
        hidden: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        tooltipPositionTop() {
            if (! this.tooltipPosition || ! this.scrollPosition) {
                return null;
            }

            return this.tooltipPosition.top - this.scrollPosition.y  + 'px';
        },
        tooltipPositionleft() {
            if (! this.tooltipPosition || ! this.scrollPosition) {
                return null;
            }
            return this.tooltipPosition.left - this.scrollPosition.x  + 'px';
        }
    },
    methods: {
        mouseEnter() {
            if (this.disabled){
                return;
            }
            this.calculatePosition();
            this.$root.$el.append(this.$refs.tooltip);

            this.scrollPosition = {
                x: window.scrollX,
                y: window.scrollY,
            };
            this.hover = true;
        },
        mouseLeave() {
            if (this.disabled){
                return;
            }

            this.resetPosition()

            this.hover = false;
        },
        close() {
            this.persistentOpen = false;
        },
        calculatePosition() {
            this.tooltipPosition = {
                top: this.$refs.tooltip.getBoundingClientRect().top,
                left: this.$refs.tooltip.getBoundingClientRect().left
            }
        },
        resetPosition() {
            this.$refs.wrapper.append(this.$refs.tooltip);
            this.tooltipPosition = null;
        }
    },
    beforeDestroy() {
        this.resetPosition()
    }
}, 'layout/ak-tooltip.html');