Vue.asyncComponent('ak-skeleton-loader', {
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        size: {
            type: String,
        }
    }
}, 'layout/loaders/ak-skeleton-loader.html');