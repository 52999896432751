Vue.asyncComponent('ak-progress-linear', {
    props: {
        colorMode: {
           type: String,
        },
        height:{
            type: Number|String,
            default: 10
        },
        percentage:{
           type: Number
        }
    },
    model: {
        prop: 'percentage',
        event: 'change'
    }
}, 'layout/progress/ak-progress-linear.html');