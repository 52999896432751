import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-number', {
	mixins: [FormElementMixin],
	props: {
		icon: {
			type: String,
		},
		min: {
			type: Number,
		},
		max: {
			type: Number,
		}
	}
}, 'form/controls/ak-number.html');
