Vue.asyncComponent('ak-header', {
    props: {
        showDrawer: {
            type: Boolean,
        },
        drawerPinned: {
            type: Boolean,
            default: false,
        }
    },
    model: {
        prop: 'showDrawer',
        event: 'toggleDrawer'
    },
    watch: {
        drawerPinned(value) {
            if (value && !this.showDrawer) {
                this.showDrawer = true;
            }
        }
    },
    methods: {
        toggleDrawer(e) {
            if (! this.drawerPinned) {
                this.$emit('toggleDrawer', !this.showDrawer);
            }
        }
    },
    created() {
        // we check if the initial state is pinned
        if (this.drawerPinned && !this.showDrawer) {
            // if so we open the drawer
            this.$emit('toggleDrawer', !this.showDrawer);
        }
    }
}, 'wrappers/ak-header.html');