import FormTrigger from "./FormTrigger";

/**
 * This class describes which matrix field has triggerd an action
 * e.g. validation of a matrix field
 */
export default class MatrixFormTrigger extends FormTrigger {

    /**
     * Constructor
     *
     * @param widgetId
     * @param rowId
     * @param groupId
     */
    constructor(widgetId, rowId, groupId = null) {
        const matrixTrigger = {
            row: rowId,
            widgetId: widgetId,
            group: groupId,
        }

        super(matrixTrigger);
    }
}