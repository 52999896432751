Vue.asyncComponent('ak-link', {
    props: {
        title: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
        href: {
            type: String,
            required: false,
            default: ''
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        },
        uppercase: {
            type: Boolean,
        },
        icon: {
            type: String,
        }
    },
    methods: {
        click(e) {
            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.target});
            }
        }
    }
}, 'layout/ak-link.html');