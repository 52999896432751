import FormTrigger from "./triggers/FormTrigger";
import AutoSaveHandler from "../../utils/handler/auto-save-handler";
import {cloneDeep} from "../../utils/helpers/object-helpers";

Vue.asyncComponent('ak-form', {
	props: {
		definition: {
			type: String,
			required: false
		},
		bundle: {
			type: String,
			required: false
		},
		objectId: {
			type: String,
			required: false,
			default: 'new'
		},
		relationString: {
			type: String,
		},
		formData: {
			type: Object
		},
		getUrl: {
			type: String,
		},
		saveUrl: {
			type: String
		},
		baseUriControls: {
			type: String,
			required: false,
			default() {
				return this.$appKitUrl() + '/' + this.bundle + '/' + this.definition;
			},
		},
		query: {
			type: Object,
			required:false
		},
		forceAutoSave: {
			type: Boolean,
			required: false,
			default: false
		},
		disableAutoSave: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			title: '',
			descr: '',
			activeTab: null,
			loading: false,
			form: {},
			baseUri: this.getUrl ? this.getUrl : this.$appKitUrl() + '/' + this.bundle + '/' + this.definition,
			saveUri: this.saveUrl ? this.saveUrl : this.$appKitUrl() + '/' + this.bundle + '/' + this.definition + '/post',
			formValues: {},
			initialFormValues: {},
			widgetGroups: {},
			ungroupedWidgets: [],
			headerWidgets: [],
			ungroupedWidgetsErrors: false,
			hasLocalizedProperties: false,
			minWidth: null,
			states: {
				visibility: {}
			},
			autoSaveHandler: null,
			dateLastModified: null,
			formWithAutoSaveVisible: false,
			formWithoutAutoSaveVisible: false,
			refreshToke: Date.now()
		}
	},
	watch: {
		minWidth() {
			this.$emit('minWidthChanged', this.minWidth);
		}
	},
	computed: {
		currentActiveTab: {
			get() {
				if (this.activeTab) {
					return this.activeTab;
				}

				if(this.relationString && this.widgetGroups !== {}) {
					const relationWidgetId = this.relationString.split(':')[0];
					let activeWidgetGroupId = null

					// find in wicht widget group the active relation widget is present and set as active tab
					Object.values(this.widgetGroups).forEach(group => {
						if (group.widgets.find(widget => widget.id === relationWidgetId)) {
							activeWidgetGroupId = group.id
						}
					});
					this.activeTab = activeWidgetGroupId;
					return activeWidgetGroupId;
				}

				return this.widgetGroups !== {}? this.widgetGroups[Object.keys(this.widgetGroups)[0]].id : null;
			},
			set(tab) {
				this.activeTab = tab;
			},
		}
	},
	methods: {
		/**
		 * Get the form Data
		 * @param formData
		 * @returns {Promise<void>}
		 */
		async getForm(formData) {
			let param = this.getBaseParams();
			this.setLoading(true);

			if(this.objectId != 'new') param.objectId = this.objectId;
			try {
				const result = await this.$get(this.baseUri, param);

				this.setForm(result);
				this.setLoading(false);
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Set the required variables from the given formData
		 * @param formData
		 */
		setForm(formData) {
			this.form = formData.form;
			this.minWidth = formData.minWidth;
			this.hasLocalizedProperties = formData.hasLocalizedProperties;
			this.dateLastModified = this.$dateHelper(formData.dateLastModified);

			this.title = formData.title ? formData.title : this.title;
			this.descr = formData.descr ? formData.descr : this.descr;

			this.createWidgetGroups();
		},
		/**
		 * Submit the formvalues to the backend and handle the reterned actions
		 * @param e
		 * @returns {Promise<void>}
		 */
		async submit(e) {
			// prevent the page from refreshing when a user enters in an input field
			if (e) {
				e.preventDefault();
			}

			this.setLoading(true);
			const path = this.saveUri;
			const body = { "formValues": { ...this.formValues }};

			let param = this.getBaseParams();
			if(this.objectId != 'new') param.objectId = this.objectId;

			try {
				const result = await this.$post(path, body, param);
				this.setLoading(false);

				// update the inital values for the changed check
				this.initialFormValues = JSON.parse(JSON.stringify(this.formValues));

				if (result.success) {
					if (! this.disableAutoSave && this.autoSaveHandler) {
						this.autoSaveHandler.removeEntry();
						this.autoSaveHandler.destroy();
					}
					this.$actionHandler.handle(result.todos, this);
				} else {
					this.setForm(result);

					// if the submit was unsuccesfull & we have groups
					// set the active tab to the first tab where errors are pressent
					if(result && !result.success && this.form.layout.groups !== null) {
						this.activeTab = this.firstWidgetGroupIdWithError();
					}
				};

				this.$emit('submit');
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Set the values of the widget in de formValues array
		 * @param result
		 */
		setInitialValues(result) {
			this.form.controlWidgets.widgets.forEach(widget => {
				this.formValues[widget.id] = cloneDeep(widget.formControl.value);

				// now we trigger the formStates for the first time
				this.applyStates(widget.id, widget.formControl.value);
			});

			// clone the initial values
			// this is needed for the is changed check
			this.initialFormValues = cloneDeep(this.formValues);
		},
		/**
		 * Get the first widget group width an error
		 * @returns {string}
		 */
		firstWidgetGroupIdWithError() {
			for (const [id, group] of Object.entries(this.widgetGroups)) {
				if(group.hasError) {
					return id;
				}
			}

			return 'ungrouped';
		},
		/**
		 * Handle widget input
		 * We set the new value
		 * @param id
		 * @param data
		 */
		handleWidgetInput(id, data) {
			this.formValues[id] = data;

			this.$emit('change', this.formValues);
		},
		/**
		 * We check if the formValues has changed
		 * If so we notify the parent object (so it can show an alert if the user wants to close this form)
		 */
		checkIfFormValuesChanged(to, from, next) {
			let changed = false;

			Object.keys(this.formValues).forEach( key => {
				const initialValue = this.initialFormValues[key];

				// check if the value is an object if so we need to compare deep
				if (typeof this.formValues[key] === 'object' && this.formValues[key] !== null) {
					if (initialValue && !this.$deepEqual(this.formValues[key], initialValue)) {
						changed = true;
					}
				} else if(this.formValues[key] !== initialValue) {
					changed = true;
				}
			});

			return changed && !to.matched.some(route => route.name === 'form');
		},
		/**
		 * Validate if the variable is an object
		 * @param object
		 * @returns {boolean}
		 */
		isObject(object) {
			return object != null && typeof object === 'object';
		},
		/**
		 * We get all the necessary event listeners for the widgets to function
		 * Some listeners will com from the formSync logic
		 * Other listeners are needed for the basic functionality of the form
		 * @param id
		 * @returns {{}}
		 */
		widgetListener(id) {
			let out = {};

			// check if we have form syncs for this control
			if(this.form.syncs && this.form.syncs[id]) {
				this.form.syncs[id].forEach(event => {
					// add th sync form function for each listener
					out[event] = this.mergeListeners(this.syncForm.bind(null, id, event), out[event]);
				});
			}

			// add the necassary listeners for ower form logic
			// we always merge the listeners width the existing listeners so we don't lose any of the form syncs listeners
			out['input'] = this.mergeListeners(this.handleWidgetInput.bind(null, id), out['input'] );
			out['updateState'] = this.mergeListeners(this.applyStates.bind(null, id), out['updateState']);
			out['blur'] = this.mergeListeners((triggers) => this.validateValue(id, triggers), out['blur']);

			return out;
		},
		/**
		 * Merge old Event listener(s) width the new on
		 * @param newListener
		 * @param oldListener
		 * @returns {*[]}
		 */
		mergeListeners(newListener, oldListener) {
			if(! oldListener) {
				return [newListener];
			}

			if( oldListener && oldListener.constructor === Array) {
				return [newListener, ...oldListener]
			}

			return [newListener, oldListener]
		},
		/**
		 * An event has triggerd a form sync
		 * We get the new formValues
		 * @param id
		 * @param event
		 * @returns {Promise<void>}
		 */
		async syncForm(id, event) {
			const baseUri = (new URL(this.baseUri));
			baseUri.pathname = baseUri.pathname + '/sync';
			const path = baseUri.toString();

			const body = {
				id: id,
				event: event,
				values: this.formValues
			};

			let param = this.getBaseParams();
			// only send the id when we have an existing object
			if (this.objectId !== 'new') {
				param.objectId = this.objectId
			}

			try {
				const result = await this.$post(path, body, param);
				this.updateFormValues(result);
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Set the new formValues
		 * @param newValues
		 */
		updateFormValues(newValues) {
			for(let id in newValues) {
				// find the widget
				let widget = this.form.controlWidgets.widgets.find(widget => widget.id == id);
				//set the new value for the widget
				// widget.formControl.value = newValues[id];
				this.handleWidgetInput(id, newValues[id]);
			}
		},
		// Extract groups from the form data
		createWidgetGroups() {
			let widgetsSelected = [];
			// Set the header widgets
			this.headerWidgets = this.form.controlWidgets.widgets.filter(widget => this.form.layout.headerWidgets.includes(widget.id));
			widgetsSelected = [...widgetsSelected, ...this.form.layout.headerWidgets];

			if (this.form.layout.groups == null) {
				this.ungroupedWidgets = this.form.controlWidgets.widgets.filter(x => !widgetsSelected.includes(x.id));
				return;
			}

			this.form.layout.groups.forEach(group => {
				this.widgetGroups[group.id] = { ...group };
				this.widgetGroups[group.id].widgets = this.form.controlWidgets.widgets.filter(widget => group.widgetIds.includes(widget.id));
				this.widgetGroups[group.id].hasError = this.widgetGroups[group.id].widgets.some(widget => widget.formControl.hasError === true);
				widgetsSelected = [...widgetsSelected, ...group.widgetIds];
			});

			this.ungroupedWidgets = this.form.controlWidgets.widgets.filter(x => !widgetsSelected.includes(x.id));
			this.ungroupedWidgetsErrors = this.ungroupedWidgets.some(widget => widget.formControl.hasError === true);
		},
		// Apply the states, this function is run after every input, only validates the changed input
		applyStates(id, data) {
			// when we don't have a form state we stop here
			if (!this.form.states || this.form.states.length === 0) return;

			// if we diden't get a widget id we quit here
			if (!id) return;

			// loop over all the states to check if there are states for the current widget
			this.form.states.forEach(state => {
				let stateFulfilled = [];

				// if the state doesn't contain a condition for the current widget we skip
				if ( !state.conditions.filter(condition => condition[0] == id).length ) {
					return;
				}

				state.conditions.forEach((condition, i) => {
					const widgetId = condition[0];
					const value = condition[1];
					const operator = condition[2];
					const currentValue = data;

					// check if the state is for the current widget
					if (widgetId !== id) return;

					// check if the provided condition matches anything
					if (this.$operationHandler(currentValue, operator, value).result()) {
						// we active the state and we will handle the needed action('s) ( change visibility / change value / refresh widget(s))
						stateFulfilled.push(true);
					} else {
						stateFulfilled.push(false);
					}
				});

				if (!stateFulfilled.includes(false) && stateFulfilled.length !== 0) {
					this.activateState(state)
				}
			});
		},
		activateState(state) {
			// check if we need to change the visibility of a widget for this state
			if (state.visibility) {
				this.states.visibility = { ...this.states.visibility, ...state.visibility };
			}

			// check if we need to change a value of a widget for this state
			if (state.changeValue) {
				Object.keys(state.changeValue).forEach(key => {
					this.form.controlWidgets.widgets.find(widget => widget.id === key).formControl.value = state.changeValue[key];
				});
			}

			// check if we need to do a refresh of a widget for this state
			if(state.refresh) {
				this.refreshWidgets(state.refresh);
			}
		},
		async refreshWidgets(refreshWidgets) {
			const baseUri = (new URL(this.baseUri));
			baseUri.pathname = baseUri.pathname + '/form-state';

			const body =  {
				"formValues": { ...this.formValues }
			};

			let params = this.getBaseParams();
			if(this.objectId != 'new') params.objectId = this.objectId;

			const result = await this.$post(baseUri.toString(), body, params);

			// now that we have the updated widgets
			refreshWidgets.forEach(refreshWidgetId => {
				// get the key of the widget we need to udate
				const key = this.form.controlWidgets.widgets.findIndex(widget => widget.id === refreshWidgetId);

				// get the new widget
				const updatedWidget = result.find(widget => widget.id === refreshWidgetId);

				Vue.set(this.form.controlWidgets.widgets, key, updatedWidget);
			});

			this.createWidgetGroups();
		},
		async validateValue(id, eventData) {
			if (!id) return;
			const baseUri = (new URL(this.baseUri));
			baseUri.pathname = baseUri.pathname + '/validateControl';
			const path = baseUri.toString();

			let param = this.getBaseParams();
			if(this.objectId != 'new') param.objectId = this.objectId;
			const validateData = {
				"widgetId": id,
				"formValues": { ...this.formValues }
			};

			// Check if the extra event data are FormTriggers
			if (eventData instanceof FormTrigger) {
				// if this is the case we send them width the validation call
				validateData['trigger'] = eventData.getTrigger();
			}

			try {
				const result = await this.$post(path, validateData, param);
				let widget = this.form.controlWidgets.widgets.find(x => x.id === id);
				widget.formControl = { ...widget.formControl, ...result[id] };

				// Do something
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Set Active tab
		 * we set the givent tab to active and we scroll to top
		 * @param tabId
		 */
		setActiveTab(tabId) {
			this.currentActiveTab = tabId;
			this.$refs.steps.scrollTo(0,0);
		},
		/**
		 * Get the base params for each form call
		 * @returns {{}}
		 */
		getBaseParams() {
			let params = {};

			if (this.query) {
				// Make sure we clone the query
				// If we don't do this we will update the $route.query element by reference when we alter the params
				params = JSON.parse(JSON.stringify(this.query));
			}

			params.locale =  this.$localeHandler.getActiveLocale();
			return params;
		},
		/**
		 * Update the loading flag
		 * @param flag
		 */
		setLoading(flag) {
			this.loading = flag;
		},
		/**
		 * Initialize auto save
		 */
		initAutoSave() {
			const autoSaveId = this.definition + '-' + this.bundle + '-' + this.objectId;
			this.autoSaveHandler = (new AutoSaveHandler(autoSaveId, this.formValues, this.dateLastModified.getDate()));

			if (this.disableAutoSave) {
				return;
			}

			if ( !this.autoSaveHandler.allowedToInitialize() && !(this.forceAutoSave && this.autoSaveHandler.getEntry())) {
				return;
			}

			this.autoSaveHandler.initAutoSave();

			if(this.autoSaveHandler.getEntry()) {
				this.formValues = cloneDeep(this.autoSaveHandler.getEntry().getData());
				this.autoSaveHandler.setData(this.formValues);
			}
		},
		/**
		 * Remove the current autosave entry
		 */
		removeAutoSave() {
			// remove the current entry
			this.autoSaveHandler.removeEntry();
			this.autoSaveHandler.destroy();
			this.autoSaveHandler = null;
			this.setInitialValues();
			this.refreshToke = Date.now();
			this.initAutoSave();
		},
		/**
		 * Show this form without auto save enabled in a new stacked window
		 */
		showFormWithAutoSave() {
			this.formWithAutoSaveVisible = true;
		},
		/**
		 * Show this form without auto save enabled in a new stacked window
		 */
		hideFormWithAutoSave() {
			this.formWithAutoSaveVisible = false;
		},
		/**
		 * Show this form without auto save enabled in a new stacked window
		 */
		showFormWithoutAutoSave() {
			this.formWithoutAutoSaveVisible = true;
		},
		/**
		 * Show this form without auto save enabled in a new stacked window
		 */
		hideFormWithoutAutoSave() {
			this.formWithoutAutoSaveVisible = false;
		},
	},
	async created() {
		// If we are given formData as a prop we use this
		if (this.formData) {
			this.setForm(this.formData);

		} else {
			// Else we will get the form
			await this.getForm();
		}

		this.setInitialValues();

		// If auto save is enabled and we don't already have a auto save handler
		if (this.form.autoSaveEnabled && ! this.autoSaveHandler && this.objectId !== 'new') {
			// We initialize auto save width the date last modified
			this.$nextTick(this.initAutoSave);
		}

		if (this.form.alertUnsavedChanges && this.form.alertUnsavedChanges.enabled) {
			this.$confirmUnsavedChangesHandler.setShowConfirmCallback(this.checkIfFormValuesChanged);
			this.$confirmUnsavedChangesHandler.setConfirmMessage(this.form.alertUnsavedChanges.message);
		}
	},
	beforeDestroy() {
		if (this.autoSaveHandler) {
			// make sure we remove the interval so it doesn't keep running
			this.autoSaveHandler.destroy();
		}
	}
}, 'form/ak-form.html');
