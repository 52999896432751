import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-color-picker', {
    mixins: [FormElementMixin],
    data() {
        return {
            pickerVisible: false,
            removeFromListener:  ['input','updateState','blur'],
        }
    },
    props: {
        colorMode: {
            type: String,
            default: 'hex'
        }
    },
    computed: {
        currentColor: {
            get(){
                if(! this.currentValue) {
                    return '#000000';
                }

                if(this.colorMode === 'hex') {
                    return this.currentValue
                }

                if(this.colorMode === 'rgb') {
                    let value = this.currentValue.trim().slice(
                            this.currentValue.indexOf("(") + 1,
                            this.currentValue.indexOf(")")
                    ).split(",");

                    return {r: parseInt(value[0]), g: parseInt(value[1]), b: parseInt(value[2])};
                }

                if(this.colorMode === 'rgba') {
                    let value = this.currentValue.trim().slice(
                            this.currentValue.indexOf("(") + 1,
                            this.currentValue.indexOf(")")
                    ).split(",");

                    return {r: parseInt(value[0]), g: parseInt(value[1]), b: parseInt(value[2]), a: parseInt(value[3])};
                }

                if(this.colorMode === 'hsl') {
                    let value = this.currentValue.trim().slice(
                            this.currentValue.indexOf("(") + 1,
                            this.currentValue.indexOf(")")
                    ).split(",");
                    console.log(value[1].replace('%', ''));
                    return {h: parseFloat(value[0]), s: parseFloat(value[1].replace('%', '') / 100), l: parseFloat(value[2].replace('%', '') / 100)};
                }

                if(this.colorMode === 'hsla') {
                    let value = this.currentValue.trim().slice(
                            this.currentValue.indexOf("(") + 1,
                            this.currentValue.indexOf(")")
                    ).split(",");

                    return {h: parseInt(value[0]), s: parseFloat(value[1].replace('%', '') / 100), l: parseFloat(value[2].replace('%', '') / 100), a: parseFloat(value[3])};
                }
            },
            set(e) {
                if(this.colorMode === 'hex') {
                    this.currentValue = e.hex;
                }

                if(this.colorMode === 'rgb') {
                    this.currentValue = `rgb(${e.rgba.r},${e.rgba.g},${e.rgba.b})` ;
                }

                if(this.colorMode === 'rgba') {
                    this.currentValue = `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})` ;
                }

                if(this.colorMode === 'hsl') {
                    this.currentValue = `hsl(${Math.ceil(e.hsl.h)},${Math.ceil(e.hsl.s * 100)}%,${Math.ceil(e.hsl.l * 100)}%)` ;
                }

                if(this.colorMode === 'hsla') {
                    this.currentValue = `hsla(${Math.ceil(e.hsl.h)},${Math.ceil(e.hsl.s * 100)}%,${Math.ceil(e.hsl.l * 100)}%,${e.hsl.a})` ;
                }

                this.$emit('blur');
            }
        }
    },
    watch: {
        currentValue() {
            if(this.currentValue === '') {
                this.pickerVisible = false;
            }
        }
    },
    methods: {
        showPicker() {
            this.pickerVisible = true;
        },
        hidePicker() {
            this.pickerVisible = false;
        },
        blur() {
            if(! this.pickerVisible) {
                this.$emit('blur');
            }
        }
    }
}, 'form/controls/ak-color-picker.html');