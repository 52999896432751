import ListWidgetMixin from "../mixins/ListWidgetMixin";

Vue.asyncComponent('ak-popover-menu', {
    mixins: [ListWidgetMixin],
    data() {
        return {
            currentOpen: false,
        }
    },
    props: {
        open: {
            type: Boolean,
            required: false,
        },
        triggerLabel: {
            type: String,
            required: false,
        },
        triggerIcon: {
            type: String,
            required: false,
        },
        direction: {
            type: String,
            default: 'left',
            required: false,
        },
        items: {
            type: Array,
            required: false,
        },
        showOverflow: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'open',
        event: 'change'
    },
    watch: {
        open(open) {
            this.currentOpen = open;
        }
    },
    methods: {
        toggleMenu() {
            this.currentOpen = !this.currentOpen;
            this.$emit('change', this.currentOpen);
        },
        closeMenu() {
            this.currentOpen = false;
            this.$emit('change', this.currentOpen);
        }
    },
    created() {
        this.currentOpen = this.open
    }
}, 'layout/ak-popover-menu.html');