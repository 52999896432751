import moment from 'moment';
import AutoSaveEntry from "./auto-save-entry";
import {deepEqual} from "../helpers/object-helpers";
import {cloneDeep} from "../helpers/object-helpers";

export default class AutoSaveHandler {
    /**
     * Constructor
     *
     * @param id
     * @param data
     * @param dateLasModified
     * @param saveTimeout
     */
    constructor(id, data, dateLasModified, saveTimeout = 2000) {
        this.id = id + '-auto-save';
        this.initialData = cloneDeep(data); // keep a hard clone that isn't reactive
        this.data = data;
        this.dateLasModified = dateLasModified;
        this.saveTimeout = saveTimeout;
        this.autoSaveActive = false;
        this.entry = null;

        this.save = this.save.bind(this);
        this.dataIsDifferent = this.dataIsDifferent.bind(this);
    }

    /**
     * Set save timeout
     * @param saveTimeout
     */
    setSaveTimeout(saveTimeout) {
        this.saveTimeout = saveTimeout;
        return this;
    }

    /**
     * Set data
     * @param data
     * @returns {AutoSaveHandler}
     */
    setData(data) {
        this.data = data;
        return this;
    }

    /**
     * Get auto save entry
     *
     * @returns {AutoSaveEntry|null}
     */
    getEntry() {
        if (! this.entry) {
            const entryData = localStorage.getItem(this.id);
            this.entry =  entryData ? AutoSaveEntry.deserialize(entryData) : null;
        }
        return this.entry;
    }

    /**
     * Get initial data
     * @returns {boolean}
     */
    getInitialData() {
        return this.initialData;
    }

    /**
     * Get auto save active
     * @returns {boolean|*}
     */
    getAutoSaveActive() {
        return this.autoSaveActive;
    }

    /**
     * Check if we have a valid entry
     * @returns {boolean}
     */
    allowedToInitialize() {
        return !this.getEntry() || !this.dateLasModified || this.getEntry().getDate().isAfter(this.dateLasModified);
    }

    /**
     * Init auto save
     */
    initAutoSave() {
        this.autoSaveActive = true;
        this.interval = setInterval(this.save, this.saveTimeout);
    }

    dataIsDifferent() {
        const entry = this.getEntry();

        if (entry) {
            return !deepEqual(this.data, entry.getData());
        }

        return !deepEqual(this.data, this.initialData);
    }

    /**
     * Save data to local storage
     */
    save() {
        if (this.dataIsDifferent()) {
            // make sure we clone the data that we don't keep any reactive data in it
            this.entry  = new AutoSaveEntry(cloneDeep(this.data));
            localStorage.setItem(this.id, this.entry.serialize());
        }
    }

    /**
     * Remove current entry
     */
    removeEntry() {
        this.entry = null;
        localStorage.removeItem(this.id);
    }

    /**
     * Destroy auto save handler
     */
    destroy() {
        clearInterval(this.interval);
    }

    /**
     * Check if there are entries older than 3 months we need to remove
     */
    static removeOldEntries() {
        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            // if this isent an auto save entry we ignore it
            if (! key.includes('auto-save')) {
                continue;
            }

            const entry = AutoSaveEntry.deserialize(localStorage.getItem(key));
            const tresholdDate = moment().subtract(3, 'months')

            // if the entry is older than 3 months
            if (entry.getDate().isBefore(tresholdDate)) {
                // we remove it
                localStorage.removeItem(key);
            }
        }
    }
}