Vue.asyncComponent('ak-iframe', {
    name:'ak-iframe',
    props: {
        src: {
            type: String,
            required: true
        },
        title: {
            type: String,
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        },
        allowFullScreen: {
            type: Boolean,
            default: true
        },
        aspectRatio: {
            type: String,
        },
    },
    computed: {
        style() {
            const style = {};

            if (this.width) {
                style['--ak-iframe-width'] = this.width;
            }

            if (this.height) {
                style['--ak-iframe-height'] = this.height;
            }

            if (this.aspectRatio) {
                style['--ak-iframe-aspect-ratio'] = this.aspectRatio;
            }

            return style;
        }
    }
}, 'layout/ak-iframe.html');