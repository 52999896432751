RedactorX.add('plugin', 'fullscreen', {
    /**
     * Init full screen plugin
     */
    init: function() {
        this.fullScreen = false;
        // We keep the initial max height
        this.initialMaxHeight = this.opts.editor.maxHeight;
        this.app.topbar.add('fullscreen', {
            title: '## fullscreen.full-screen-title ##',
            icon: '<span class="ak-icon"> open_in_full </span>',
            command: 'fullscreen.toggle'
        });
    },

    /**
     * Toggle the full screen mode
     */
    toggle: function() {
        this.fullScreen = !this.fullScreen;
        this.app.container.$main.nodes[0].classList.toggle('rx-container--full-screen');
        this.app.editor.$editor.nodes[0].style.maxHeight = this.fullScreen ? null : this.initialMaxHeight;

        this.toggleTopBarButton();
    },

    /**
     * Change the icon of the topbar button
     */
    toggleTopBarButton() {
        this.app.topbar.get('fullscreen').nodes[0].innerHTML = this.fullScreen ?
                '<span class="ak-icon"> close_fullscreen </span>' :
                '<span class="ak-icon"> open_in_full </span>';
    }
});