import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-address', {
    mixins: [FormElementMixin],
    data() {
        return {
            localAddress: {
                street: null,
                streetNumber: null,
                extra: null,
                city: null,
                postalCode: null,
                country: null,
                lat: null,
                lng: null,
            },
            googleApiKey: this.$getSetting('google.apiKey'),
            timer: null,
            map: null,
            markers: [],
        }
    },
    props: {
        countryOptions: {
            type: String,
            default: null,
        },
        showMap: {
            type: Number|Boolean,
            default: false
        }
    },
    watch: {
        localAddress: {
            handler(newValue) {
                this.currentValue = newValue;

                // if we have an api key we can get the lat and long for the given address
                if (this.googleApiKey) {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(this.getLangLong, 500);
                }
            },
            deep: true
        },
        currentValue(newValue) {
            if (newValue !== this.localAddress) {
                this.localAddress = {...this.localAddress, ...newValue};
            }
        }
    },
    computed: {
        activeCountryOptions() {
            let countries = JSON.parse(this.countryOptions);

            if (!countries) {
                return [];
            }

            return Object.keys(countries).map((key) => {
                return {
                    value: key,
                    label: countries[key],
                }
            });
        }
    },
    methods: {
        addGoogleMapsScript() {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://maps.googleapis.com/maps/api/js?key=" + this.googleApiKey;
            document.body.appendChild(script);

            script.onload = this.renderMap;
        },
        renderMap() {
            const myLatLng = { lat: 50.5039, lng: 4.4699 };
            this.map = new google.maps.Map(this.$refs.map, {
                zoom: 7,
                center: myLatLng,
                disableDefaultUI: true,
            });
        },
        clearMarkers() {
            this.markers.forEach(marker => {
                console.log(marker);
                marker.setMap(null);
            })

            this.markers = [];
        },
        renderMarker() {
            this.clearMarkers();

            const map = this.map
            const marker = new google.maps.Marker({
                position: {lat: this.localAddress.lat, lng: this.localAddress.lng},
                map
            });

            this.map.setZoom(11);
            this.map.setCenter( {lat: this.localAddress.lat, lng: this.localAddress.lng});

            this.markers.push(marker);
        },
        /**
         * We da an api key to google to get the Lat and Long for the current address
         * @returns {Promise<void>}
         */
        async getLatLng() {
            const params = {
                address: this.getAddressFull(),
                key: this.googleApiKey,
            }

            const result = await this.$get('https://maps.google.com/maps/api/geocode/json', params);

            if (result.status === 'OK' && result.results.length) {
                this.localAddress.lat = result.results[0].geometry.location.lat;
                this.localAddress.lng = result.results[0].geometry.location.lng;
                // show a marker on the position
                this.renderMarker();
            }
        },
        getAddressFull() {
            return `${this.localAddress.street} ${this.localAddress.streetNumber} ${this.localAddress.extra} ${this.localAddress.postalCode} ${this.localAddress.city} ${this.localAddress.country}`;
        }
    },
    created() {
        if (this.value) {
            this.localAddress = {...this.localAddress, ...this.value};
        }

        if (this.showMap) {
            if (window.google == undefined) {
                this.addGoogleMapsScript();
            } else {
                this.$nextTick(this.renderMap);
            }
        }
    }
}, 'form/controls/ak-address.html');