import FormElementRelationMixin from '../mixins/FormElementRelationMixin.js';

Vue.asyncComponent('ak-relation', {
    mixins: [FormElementRelationMixin],
    props: {
        enableForeignList: {
            type: Boolean,
            default: true
        },
        baseUri: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleteDialogItemId: null,
        }
    },
    watch: {
        currentRelation: {
            deep: true,
            handler() {
                this.setCurrentValue(this.currentRelation.selected);
            }
        },
        value(newValue, oldValue) {
            if(! this.$deepEqual(newValue,oldValue)) {
                this.currentRelation.selected = newValue;
            }
        },
    },
    methods: {
        /**
         *
         * @returns {{name: string, params: {}}}
         */
        relationUrl() {
            let params = {};
            params = Object.assign(params, this.$route.params);

            let currentRelationAddon = this.id + ':grid:' + this.currentRelation.foreignList.bundle + ':' + this.currentRelation.foreignList.id;

            // if foreignList is disabled show the form imidiatly and add the dataObject to the selection on save
            if(! this.enableForeignList) {
                currentRelationAddon = this.id + ':form:' + this.currentRelation.foreignList.bundle + ':form:new';
            }

            if(params.relationString != undefined) {
                params.relationString += ':' + currentRelationAddon;
            } else {
                params.relationString = currentRelationAddon;
            }

            return {
                name: this.$route.name,
                params: params,
                query: this.$route.query
            }
        },
        editSelectedUrl(selectedItemId) {
            // we start from the currentRelationUrl because this has the logic of enableForeignList + addon
            let to = this.relationUrl()
            let relationString = to.params.relationString;
            relationString = relationString.substr(0, relationString.indexOf(':new'));
            to.params.relationString = relationString + ':' + selectedItemId;

            return this.$router.resolve(to).href;
        },
        /**
         *
         * @param widget
         * @param widgetKey
         * @param item
         * @returns {*}
         */
        addOnWidgetAttributes(widget, widgetKey, item) {
            // if we have a form control we check if we need to set a value
            let out = { ...widget.formControl, ...widget.attributes, id: widget.id, baseUri: this.baseUri };

            // if we hava a value add it
            if(!out.value && item.addOnValues[widgetKey]) {
                out.value = item.addOnValues[widgetKey];
            }

            return out;
        },
        /**
         *
         * @param id
         */
        openDeleteDialog(id) {
            this.deleteDialog = true;
            this.deleteDialogItemId = id;
        },
        /**
         *
         * @param widgetKey
         * @param item
         * @param value
         */
        handleWidgetInput( widgetKey, item, value) {
            if(!value) return;

            // we can't just set the addOnValues like addOnValues[widgetKey] = value beceause with a new item vue wil not pickup on these changes
            let currentSelection = this.currentRelation.selected.find(selectedItem => selectedItem.id == item.id);
            let addOnValues = {...currentSelection.addOnValues};

            addOnValues[widgetKey] = value;
            currentSelection.addOnValues = addOnValues;
        },
        /**
         *
         * @param id
         * @param values
         */
        async validateAddOnValue(id, values, item) {
            if (!id) return;
            const path = `/${this.bundleId}/${this.definitionId}/validate-add-on`;
            const validateData = {
                "addOnId": id,
                "addOnValues": { ...values }
            };

            try {
                const result = await this.$post(path, validateData);
                let widget = this.currentRelation.selected.find(selectedItem => selectedItem.id == item.id).widgets.addOns.find(item => item.id == id);
                widget.formControl = { ...widget.formControl, ...result[id] };

                // Do something
            } catch (error) {
                console.log(error);
            }
        },
        /**
         *
         * @param selected
         */
        setCurrentValue(selected) {
            let out = [];
            
            selected.forEach(item => {
                out.push({
                    id: item.id,
                    addOnValues: item.addOnValues,
                    entry: item.entry ? item.entry : null
                });

            });

            this.currentValue = out;
        },
        formatStateOutput(e) {
            // if we have no value return it
            if(! e) {
                return e;
            }

            // check if we can have multiple values or not
            if (! this.relation.enableMultiple) {
                // if we can only have one value return the first id;
                return Array.isArray(e) && e[0] ?
                        e[0].id :
                        e;
            } else {
                return e.map(value => value.id);
            }
        },
        /**
         * Trigger close
         */
        triggerClose() {
            this.$router.back();
        }
    }
}, 'form/controls/ak-relation.html');
