Vue.asyncComponent('ak-widget-list', {
    props: {
        widgets: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    methods: {
        handleWidget(e) { 
            this.$emit('handleWidget', e);
        }
    },
    created() {
      console.log(this.widgets);
    }
}, 'grid/layout/ak-widget-list.html');