Vue.asyncComponent('ak-snackbar', {
    props: {
        timeout: {
            type: Number,
            default: 10000
        },
        message: {
            type: String|Boolean,
        }
    },
    model: {
        prop: 'message',
        event: 'remove'
    },
    methods: {
        remove() {
            this.$emit('remove', null);
        }
    },
    created() {
        if (this.timeout) {
            setTimeout(this.remove, this.timeout);
        }
    }
}, 'layout/ak-snackbar.html');