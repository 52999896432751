Vue.asyncComponent('ak-image', {
    name:'ak-image',
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        },
        objectFit: {
            type: String,
        },
        objectPosition: {
            type: String,
        },
        backgroundColor: {
            type: String,
        },
    },
    computed: {
        style() {
            const style = {};

            if (this.width) {
                style['--ak-image-width'] = this.width;
            }

            if (this.height) {
                style['--ak-image-height'] = this.height;
            }

            if (this.objectFit) {
                style['--ak-image-object-fit'] = this.objectFit;
            }

            if (this.objectPosition) {
                style['--ak-image-object-position'] = this.objectPosition;
            }

            if (this.backgroundColor) {
                style['--ak-image-background-color'] = this.backgroundColor;
            }

            return style;
        }
    }
}, 'layout/ak-image.html');