import moment from 'moment';
import DateHelper from "../helpers/date-helper";

export default class AutoSaveEntry {
    /**
     * Constructor
     * @param data
     */
    constructor(data) {
        this.data = data;
        this.date = Date.now();
    }

    /**
     * Get date
     * @returns {DateHelper}
     */
    getDate() {
        return (new DateHelper(this.date));
    }

    /**
     * Get data
     * @returns {*}
     */
    getData() {
        return this.data;
    }

    /**
     * Set date
     * @param date
     * @returns {AutoSaveEntry}
     */
    setDate(date) {
        this.date = date;
        return this;
    }

    /**
     * Serialize the entry
     * @returns {string}
     */
    serialize() {
        return JSON.stringify(this);
    }

    /**
     * Deserialize the entry
     * @param json
     * @returns {AutoSaveEntry}
     */
    static deserialize(json) {
        const entryData = JSON.parse(json);
        return (new this(entryData.data)).setDate(entryData.date);
    }
}