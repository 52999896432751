// Helpers for numbers

/**
 * Check if number is of type int
 * @param number
 * @returns {boolean}
 */
export function isInt(number) {
    return number === +number && number === (number|0);
}

/**
 * Check if number is of type float
 * @param number
 * @returns {boolean}
 */
export function isFloat(number) {
    return number === +number && number !== (number|0);
}