Vue.asyncComponent('ak-filters', {
    emptyState: {
        selectedFilter: '',
        filter: {
            values: []
        }
    },
    props: {
        filters: {
            type: Object,
            required: false,
            default: () => {}
        },
        activeFilter: {
            type: Object,
            required: false,
            default: null
        },
        value: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {
            selectedFilter: '',
            globalErrorMessage: null,
            filter: {
                values: []
            }
        }
    },
    computed: {
        filterNames() {
            if (this.filters.filters) return this.filters.filters.map(x => x.title);
            else return [];
        },
        getFilterWidgets() {
            let widgets = [];
            if (this.selectedFilter !== '') widgets = this.filters.filters.find(x => x.title === this.selectedFilter).steps;

            // set the active filter values
            for (const key in this.filter.values) {
                const widget = widgets.find(widget => widget.index == key);
                if(widget) {
                    widget.widget.formControl.value = this.filter.values[key];
                }
            }
            
            return widgets;
        },
        show: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('close');
            }
        },
    },
    methods: {
        confirmDialog() {
            if (!this.selectedFilter) return;

            const filter = this.filters.filters.find(x => x.title === this.selectedFilter);

            if(! this.isValid(filter)) {
                return;
            }

            this.$emit('filterAdded', {
                ...filter,
                values: [ ...this.filter.values ]
            });
        },
        /**
         * Check if a filter is required and add front-end validation
         * @param filter
         * @returns {boolean}
         */
        isValid(filter) {
            let hasErrors = true;
            // check the validation field
            this.filter.values.forEach((value, key) => {
                // get the relating widget
                const widget = filter.steps[key].widget;

                // if not valid we set the widgets error messeage
                if(widget.formControl.required && !value) {
                    hasErrors = false;
                    widget.formControl.hasError = true;
                    widget.formControl.error = 'Gelieve dit veld in te vullen';
                } else {
                    // else we reset
                    widget.formControl.hasError = false;
                    widget.formControl.error = null;
                }
            });

            return hasErrors;
        },
        cancelDialog() {
            Object.assign(this.$data, this.emptyState);
            this.$emit('close');
        },
        handleWidgetInput(e, i) {
            this.filter.values[i] = e;
        }
    },
    watch: {
        value() {
            this.dialogVisible = this.showDialog;
            if (!this.showDialog) Object.assign(this.$data, this.emptyState);
            this.filter.values = [];
        }
    },
    mounted() {
        if (this.activeFilter !== null) {
            this.selectedFilter = this.filters.filters.find(x => x.id === this.activeFilter.id).title;
            this.filter.values = [ ...this.activeFilter.values ];

            // when the value is a number we parse it to an int
            if ( ! isNaN(this.activeFilter.values[1])) {
                this.filter.values[1] = parseInt(this.activeFilter.values[1]);
            }
        } else if (this.filters.filters.length === 1) {
            this.selectedFilter = this.filters.filters[0].title;
        }
    }
}, 'grid/ak-filters.html');