import {getSetting} from './settings';

export default class ErrorHandler {

    constructor() {
        this.errors = [];

        this.sendError = this.sendError.bind(this);
    }

    /**
     * Handle error
     * @param error
     * @returns {*}
     */
    handleError(error) {
        switch (error.code) {
            // case 400:
            //     return sendError('Error 400');
            case 401:
                // redirect to login screen if we have a login path
                if (AppKit.getApp().$router.resolve({name: 'login'}).route.matched.length) {
                    AppKit.getApp().$router.push({name: 'login'})
                }
                return this.sendError({code: error.code, message:'Sorry, je hebt geen toestemming om deze actie uit te voeren. Zorg ervoor dat je ingelogd bent en de juiste rechten hebt.'});
            // case 404:
            //     return sendError('Error 404');
            case 404:
                return this.sendError({code: error.code, message: 'Sorry, er is een probleem opgetreden bij het ophalen van de gevraagde gegevens. Probeer het later opnieuw.'});
            case 500:
                return this.sendError({code: error.code, message:'Sorry, er is iets misgegaan op onze server. Probeer het later opnieuw'});
            default:
                return this.sendError({code: error.code, message:'Sorry, er is iets misgegaan. Probeer het later opnieuw.'});
        }
    }

    /**
     * Send error
     * @param error
     */
    sendError(error) {
        // Only push the error if we don't already have an error message for this code
        if (! this.errors.find(currentError => currentError.code === error.code)) {
            this.errors.push(error);
        }
    }
}