import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-textarea', {
	mixins: [FormElementMixin],
	props: {
		rows: {
			type: Number|String,
			required: false,
			default: 5
		}
	}
}, 'form/controls/ak-textarea.html');