import ListWidgetMixin from "../mixins/ListWidgetMixin";

Vue.asyncComponent('ak-list', {
    mixins: [ListWidgetMixin],
    props: {
        items: {
            type: Array,
            required: false,
        },
        showDots: {
            type: Boolean,
            required: false,
            default: false
        },
        showNumbers: {
            type: Boolean,
            required: false,
            default: false
        },
    },
}, 'layout/ak-list.html');