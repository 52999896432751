import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-input-password', {
	mixins: [FormElementMixin],
	data() {
		return {
			showPassword: false,
		}
	},
	props: {
		icon: {
			type: String,
		}
	}
}, 'form/controls/ak-input-password.html');