Vue.asyncComponent('ak-loader', {
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        static: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
        },
        /**
         * By default the overlay is dark in some cases we may want a light overlay
         */
        lightOverlay: {
            type: Boolean,
        }
    }
}, 'layout/loaders/ak-loader.html');