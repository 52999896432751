Vue.asyncComponent('ak-button', {
    props: {
        title: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
        enableConfirm: {
            type: Boolean,
            required: false,
            default: false
        },
        confirmTitle: {
            type: String,
            required: false,
        },
        confirmText: {
            type: String,
            required: false,
        },
        href: {
            type: String,
            required: false,
            default: ''
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        disableBackground: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        colorMode: {
            type: String,
            required: false,
            default: ''
        },
        size: {
            type: String,
            required: false,
        },
        active: {
            type: Boolean,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        fullWidth: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            showConfirm: false,
            clickActive: false,
            clickOut: false,
        }
    },
    methods: {
        click(e) {
            if (this.enableConfirm) {
                this.showConfirm = true;
                return;
            }

            // If a href is set, only use that.
            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.target});
            }
            this.$emit('click', e);
        },
        setClickActive: function () {
            this.clickActive = true;

            setTimeout(() => {
                this.clickActive = false;
                this.setClickOut();
            }, 300)
        },
        setClickOut() {
            this.clickOut = true;

            setTimeout(() => {
                this.clickOut = false;
            }, 300)
        },
        onConfirm(e) {
            this.showConfirm = false;

            // If a href is set, only use that.
            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.target});
            }
            this.$emit('click', e);
        },
        onCancel(e) {
            this.showConfirm = false;
        }
    }
}, 'layout/ak-button.html');