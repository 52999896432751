const ListWidgetMixin = {
    props: {
        id: {
            type: String | Number,
        },
        callbackUri: {
            type: String,
        },
        contextType: {
            type: String,
        },
    },
};

export default ListWidgetMixin;