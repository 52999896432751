Vue.asyncComponent('ak-filters-dropdown-item', {
    data() {
        return {
            open: false,
            values: []
        }
    },
    props: {
        filter: {
            type: Object,
            required: true
        }
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        handleWidgetInput(e, i) {
            this.values[i] = e;
        },
        init() {
            this.open = false;
            this.filter.steps.forEach(step => {
                this.values[step.index] = step.widget.formControl.value;
            });
        },
        add() {
            this.$emit('filterAdded', {
                ...this.filter,
                values: [ ...this.values ]
            });

            this.$nextTick(this.init);
        }
    },
    created() {
        this.init();
    }
}, 'grid/filters/ak-filters-dropdown-item.html');