import {getSetting} from "./settings";

let dictionary = {};
let reportedMissing = [];

// Support for legacy t function outside of Vue.
window.t = (untranslated) => text(untranslated, placeholders);

export const fetchDictionary = async (url) => {
    try {
        const request = await fetch(url, {
            method: 'GET'
        });
        if (request.status !== 200) throw `Error ${request.status}`;
        const response = await request.json();
        dictionary = { ...dictionary, ...response};
    } catch (error) {
        console.log(error);
        throw error;
    }
} 

const isFetched = () => {
    return Object.entries(dictionary).length !== 0;
}

/**
* Get Translations, for given untranslated text
*
* @param {Polyglot} instance
* @param {String} untranslated
* @returns {{}}
*/
const translationObject = (untranslated) => {
    if (!untranslated) return null;

    untranslated = untranslated.trim();

    if (!dictionary.texts[untranslated]) {
        reportMissing(untranslated);
        return null;
    }

    return dictionary.texts[untranslated];
}

export const text = (untranslated, placeholders) => {
    // Don't translate when there is no fetched dictionary, but we still need to insert
    // placeholders when present.
    if ( ! isFetched()) {
        return insertPlaceholders(untranslated, placeholders);
    }
    // Get translation object, for the untranslated text:
    const translation = translationObject(untranslated);

    // If not found, we return the untranslated (but we do insert the
    // placeholders before we return)
    if ( ! translation) {
        return insertPlaceholders(untranslated, placeholders);
    }

    // If still here, we have found a translation. Note however that
    // this translation might contain multiple translated texts (for
    // plural forms). This should not happen, since such a text should
    // be called with plural() instead of text(). We'll default to the
    // first text encountered, if this occurs:
    if (typeof translation.str === 'object') {
        for (var i in translation.str) {
            return insertPlaceholders(translation.str[i], placeholders);
        }
    }

    // If still here, it is a regular translation. So, return:
    return insertPlaceholders(translation.str, placeholders);
}

export const plural = (untranslated, untranslatedPlural, count, placeholders) => {
    // First, we apply the default plural formula:
    var n = count == 1 ? 0 : 1;

    // Make sure the count is a placeholder value, too:
    placeholders = { count : count, ...placeholders};

    if (!isFetched()) {
        return insertPlaceholders(n == 0 ? untranslated : untranslatedPlural, placeholders);
        // return n ? untranslatedPlural : untranslated;
    }

    // Get translation object, for the untranslated text:
    var translation = translationObject(untranslated);

    // If not found, we return the untranslated text. We pick between singular
    // and plural, and we also insert placeholders:
    if (!translation) return insertPlaceholders(n == 0 ? untranslated : untranslatedPlural, placeholders);

    // If still here, we have found the translation object. We'll look for the
    // appropiate plural form there. If it does not exist, again, we return the
    // untranslated string:
    const found = (typeof translation.str == 'object' && typeof translation.str[n] == 'string');
    if ( ! found) {
        return insertPlaceholders(n == 0 ? untranslated : untranslatedPlural, placeholders);
    }

    // Return translated string:
    return insertPlaceholders(translation.str[n], placeholders);
}

const insertPlaceholders = (text, placeholders) => {
    if ( ! text) {
        return '';
    }

    text = text.toString();

    if (typeof placeholders !== 'object') {
        return text;
    }

    Object.keys(placeholders).forEach(element => {
        const name = (element.substr(0, 1) !== '@') ? '@' + element : element;
        text = text.replace(name, placeholders[element]);
    });

    return text;
}

const reportMissing = async (untranslated) => {

    if (reportedMissing.indexOf(untranslated) > -1) {
        return;
    }
    reportedMissing.push(untranslated);

    const url = getSetting('paths.missingTranslation');
    if ( ! url) {
        // NO API ENDPOINT AVAILABLE YET?
        return;
    }
    try {
        const request = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({string: untranslated})
        });
        if (request.status !== 200) {
            throw `Server Error! ${request.status}`;
        }
        const result = request.json();
        dictionary = { ...dictionary, ...result};
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const translatePlugin = {
    install: (Vue) => {

        const lang = getSetting('defaultLocale');

        if (getSetting('paths.translations') && lang) {
            const translationUrl = getSetting(`paths.translations.${lang}`);

            if(translationUrl) {
                fetchDictionary(translationUrl);
            }
        } else if (getSetting('paths.assets')) {
            const baseTranslations = `${getSetting('paths.assets')}/translations/${lang}.json`;
            fetchDictionary(baseTranslations);
        }

        Vue.prototype.$t = (untranslated, placeholders) => text(untranslated, placeholders);
        Vue.prototype.$p = (untranslated, untranslatedPlural, count) => {
            return plural(untranslated, untranslatedPlural, count);
        }

        Vue.directive('translate', {
            inserted: (el) => el.textContent = text(el.textContent),
            update: (el) => el.textContent = text(el.textContent)
        });
        Vue.directive('translate-plural', {
            inserted: (el) => el.textContent = plural(el.textContent),
            update: (el) => el.textContent = plural(el.textContent)
        });
    }
}