import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-form-tabs', {
    mixins: [FormElementMixin],
    props: {
        tabs: {
            type: Array,
            required: true
        }
    },
    methods: {
        async onClick(tab) {
            if (tab.disabled) {
                return;
            }

            const actions = [{
                type: 'route',
                route: tab.url,
                target: null
            }];
            AppKit.getApp().$actionHandler.handle(actions, null);
        }
    }
}, 'form/controls/ak-form-tabs.html');
