import FormElementMixin from '../mixins/FormElementMixin.js';
import FormElementWithPlaceholdersMixin from "../mixins/FormElementWithPlaceholdersMixin";

Vue.asyncComponent('ak-textarea-with-placeholders', {
    mixins: [FormElementMixin, FormElementWithPlaceholdersMixin],
    props: {
        rows: {
            type: Number,
            required: false,
            default: 5
        }
    }
}, 'form/controls/ak-textarea-with-placeholders.html');