Vue.asyncComponent('ak-localization-button', {
    props: {
        locales: {
            type: Array|String,
            required: false,
        }
    },
    computed: {
        activeLocale() {
            return this.$localeHandler.getActiveLocale();
        },
        currentLocales() {
            return this.locales.map(locale => {
                return {
                    label: locale,
                    active: this.$localeHandler.getActiveLocale() === locale,
                }
            });
        }
    },
    methods: {
        localeItemClicked(locale) {
            this.$emit('click', locale);
        }
    }
}, 'locale/ak-localization-button.html');