import moment from 'moment';
import {text} from "../polyglot";

export default class DateHelper {
    constructor(date) {
        this.date = date;
        this.moment = moment(date);
    }

    /**
     *
     * @returns {*}
     */
    getDate() {
        return this.date;
    }

    /**
     * Get date
     * @returns {*|moment.Moment}
     */
    getMoment() {
        return this.moment;
    }

    /**
     * Format the given date
     * see https://momentjs.com/docs/#/displaying/format/
     * @param format
     * @returns {string}
     */
    format(format) {
        return this.moment.format(format);
    }

    /**
     * Check if our date is before the given date
     * @returns {boolean}
     */
    isBefore(date) {
        return this.moment.isBefore(date);;
    }

    /**
     * Check if our date is after the given date
     * @returns {boolean}
     */
    isAfter(date) {
        return this.moment.isAfter(date);
    }

    /**
     * Get day
     * @returns {string}
     */
    getDayRelative() {
        // check if today
        if (this.moment.isSame(moment(), 'day')) {
            return text('vandaag');
        }

        // check if this year
        if (this.moment.isSame(moment(), 'year')) {
            return this.moment.format('D MMMM');
        }

        return this.moment.format('D MMMM YYYY');
    }

    /**
     * Get time
     * @returns {string}
     */
    getTime() {
        return this.moment.format('HH:mm');
    }
}