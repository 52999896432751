class ActionHandler {
    /**
     * Constructor
     */
    constructor() {
        // add the default actions
        this.actions = {
            'route': this.handleRoute,
            'message': this.handleMessage,
            'event': this.handleEvent,
            'refresh': this.handleRefresh,
            'trigger-confirm': this.handleTriggerConfirm
        }
    }

    /**
     * Add a new action to the action handler
     * @param string name
     * @param function callback
     */
    addAction(name, callback) {
        if (typeof callback !== 'function') {
            console.log(`Could not add action: ${name} the callback isen't a function`);
            return;
        }

        this.actions[name] = callback;
    }

    /**
     * Handle the given actions on the given component
     * @param actionsToHandle
     * @param component
     */
    handle(actionsToHandle, component) {
        // Function should never be called without actions, but still check for it.
        if (actionsToHandle.length === 0) return;

        // Globally execute global functions (route and message)
        actionsToHandle.forEach(async actionToHandle => {
            // get the action by the given type
            const action = this.actions[actionToHandle.type];

            // If we didn't find a matching action
            if (! action) {
                console.log(`No action found for type ${actionToHandle.type}`);
                // We stop here
                return;
            }

            // trigger the action if we have one
            await action(actionToHandle, component);
        });
    }

    /**
     * Redirect to the given target
     * The target can be a path o a full url
     * @param action
     * @returns {Promise<void>}
     */
    handleRoute(action) {
        const route = action.route;
        const target = action.target;
        const app = AppKit.getApp();
        let currentRoute;

        if ( typeof route === 'object') {
            currentRoute = app.$router.resolve(route).href;
        } else {
            currentRoute = route;
        }

        if (typeof route === 'string' && route.substr(0,4) === 'http') {
            window.open(route, target ? target : '_self');
            return;
        }

        if (currentRoute.charAt(0) === '#') {
            currentRoute = currentRoute.substr(1);
        }

        if (app.$route.path === currentRoute) {
            app.$router.push({
                path: currentRoute,
                query: {
                    t: Date.now()
                }
            });
            return;
        }

        app.$router.push({
            path: currentRoute
        });
    }

    /**
     * Push the message to the global messages array
     * @param message
     */
    handleMessage(message, component) {
        AppKit.getApp().globalMessages.push({
            show: true,
            text: message.title ? message.title : message.text,
            level: message.level
        });
    }

    /**
     * Trigger the given event on te given component
     * @param action
     * @param component
     */
    handleEvent(action, component) {
        component.$emit(action.eventName, action.eventData);
    }

    /**
     * Trigger the refresh event on the given component
     * @param action
     * @param component
     */
    handleRefresh(action, component) {
        component.$emit('refresh', action.eventData);
    }

    /**
     * Show the global confirm width the given settings
     * @param action
     * @param component
     */
    handleTriggerConfirm(action, component) {
        AppKit.getApp().$globalConfirm.setConfirmTitle(action.confirmData.confirmTitle);
        AppKit.getApp().$globalConfirm.setConfirmText(action.confirmData.confirmText);
        AppKit.getApp().$globalConfirm.setConfirmLabel(action.confirmData.confirmLabel);
        AppKit.getApp().$globalConfirm.setCancelLabel(action.confirmData.cancelLabel);
        AppKit.getApp().$globalConfirm.setConfirmEvent(action.confirmData.confirmEventName);
        AppKit.getApp().$globalConfirm.setCancelEvent(action.confirmData.cancelEventName);
        AppKit.getApp().$globalConfirm.setEventData(action.confirmData.eventData);
        AppKit.getApp().$globalConfirm.setBaseComponent(component);
        AppKit.getApp().$globalConfirm.setShow(true);
    }
}

export default {
    install: (Vue) => {
        Vue.prototype.$actionHandler = new ActionHandler();
    }
}