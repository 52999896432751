Vue.component('ak-widget', {
    functional: true,
    name: "AkWidget",
    props: {
        type: {
            type: String,
            required: true
        },
        contextType: {
            type: String,
            required: false
        },
        columnId: {
            type: String,
            required: false
        },
        objectId: {
            type: String,
            required: false
        },
        attributes: {
            type: Object,
            required: false,
            default: () => {}
        },
        slot: {
            type: Array,
            required: false,
            default: () => []
        },
        callbacks: {
            type: Array,
            required: false,
            default: () => []
        },
        callbackUri: {
            type: String,
            required: false,
            default: () => window.location.toString()
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    render(createElement, { props, listeners, slots, data, scopedSlots }) {
        let handlers = {};
        let tmp;
        let currentElement;
        let ref = props.type + '-' + props.attributes.id + (new Date()).getTime();

        let widgetCallbackStatuses = {
            loading: false,
            success: null,
            failure: false
        };

        for (const e in listeners) {
			tmp = listeners[e];

            if (props.callbacks.findIndex(x => x === e) === -1) {
                handlers[e] = tmp;
                continue;
            }

            handlers[e] = () => {
                // Execute callback here
                console.log('callback first. Use ', props.callbackUri);
                tmp();
			}
        }

        for (e in props.callbacks) {
            let callback = props.callbacks[e];
            // if no current listener is bind to the event
            if (handlers[callback] === undefined) {
                // we will use the callback logic
                handlers[callback] = async(e) => {
                    // Execute callback here

                    widgetCallbackStatuses.success = null;
                    widgetCallbackStatuses.failure = false;
                    widgetCallbackStatuses.loading = true;

                    const params = {
                        ...props.paramsForCallback,
                        contextType: props.contextType,
                        widgetId: props.attributes.id,
                        objectId: props.objectId,
                        columnId: props.columnId,
                        event: callback,
                        value: e
                    };
                    try {
                        let url;
                        if(props.callbackUri.substring(0,4) === 'http') {
                            const baseUri = (new URL(props.callbackUri));
                            baseUri.pathname = baseUri.pathname + '/widget-event';
                            url = baseUri.toString();
                        } else {
                            url = props.callbackUri + 'widget-event';
                        }

                        const currentWidget = currentElement.context.$refs[ref];
                        currentWidget.$emit('widget-event-callback-loading', true);
                        const result = await AppKit.getApp().$get(url, params, null);
                        currentWidget.$emit('widget-event-callback-loading', false);
                        AppKit.getApp().$actionHandler.handle(result, currentWidget);

                        widgetCallbackStatuses.success = true;
                        widgetCallbackStatuses.loading = false;
                    } catch (error) {
                        widgetCallbackStatuses.failure = true;
                        console.debug(error);
                    }
                }
            }
        }


        let currentScopeSlots = scopedSlots;
        props.slot.forEach(slot => {
            switch (slot.type) {
                case 'widget':
                    currentScopeSlots[slot.id] = props => createElement('ak-widget',{
                        props: { ...slot.value}
                    })
                    break;
                case 'slot':
                    currentScopeSlots[slot.id] = props => createElement('span', slot.value)
                    break;
            }
        });

        currentElement = createElement(
            props.type,
            {
                // attrs: props.attributes,
                // merge attributes an all the props set on the widget
                props: {...props, ...props.attributes, widgetCallbackStatuses: widgetCallbackStatuses},
                ref: ref,
                key: data.key,
                scopedSlots: currentScopeSlots,
                on: handlers
            },
            [slots().default],
        );

        return currentElement;
    }
}, false);
