import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-select', {
	name: 'ak-select',
	mixins: [FormElementMixin],
    props: {
        selectOptions: {
            type: Array,
            required: false,
            default: () => []
        },
        allowMultiple: {
            type: Boolean|Number,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        multiple() {
            return this.allowMultiple == 1;
        },
	},
    methods: {
        reduce(option) {
            return option.value;
        }
    }
}, 'form/controls/ak-select.html');