import ErrorHandler from './error-handler';
import {generateUrl} from './settings';

let defaultHeaders = {};
const activeErrorHandler = new ErrorHandler();

// process a fetch call with the try catch logic & optional inspector
const doFetch = async (url, params, inspector) => {
    try {
        // we will keep the start timestamp so we can calculate the time of a call
        let timestampStart = (new Date()).getTime();

        const request = await fetch(url, params);

        // if something when't wrong
        if ( ! request.ok)  {
            // we throw the error of the request
            throw ({code: request.status, message: request.statusText});
        }

        const response = await request.json();

        if(inspector) {
            // calculate the time of a call and set it to the optional inspector
            inspector.milliseconds = (new Date()).getTime() - timestampStart
        }

        return response;
    } catch (error) {
        activeErrorHandler.handleError(error);
        throw error;
    }
};

export const get = async (path, params, signal, inspector) => {
    const url = generateUrl(path, params);

    return doFetch(url, {
        method: 'GET',
        headers: defaultHeaders,
        params,
        signal
    }, inspector);
}

export const post = async (path, data, params, signal, inspector) => {
	const url = generateUrl(path, params);
	const body = JSON.stringify(data);

    return await doFetch(url, {
        method: 'POST',
        body,
        defaultHeaders,
        signal
    }, inspector);
}

export const put = async (path, data, params, signal, inspector) => {
	const url = generateUrl(path, params);
    const body = JSON.stringify(data);

    return await doFetch(url, {
        method: 'PUT',
        body,
        defaultHeaders,
        signal
    }, inspector);
}

/**
 * Set default header
 * @param headerLabel
 * @param value
 */
export const setDefaultHeader = (headerLabel, value) => {
    defaultHeaders[headerLabel] = value;
}

/**
 * Remove default header
 * @param headerLabel
 */
export const removeDefaultHeader = (headerLabel) => {
    delete defaultHeaders[headerLabel];
}


const dataManager = {
    install: (Vue) => {
        Vue.prototype.$get = (url, params, inspector) => get(url, params, inspector);
        Vue.prototype.$post = (url, data, params, inspector) => post(url, data, params, inspector);
        Vue.prototype.$put = (url, data, params, inspector) => put(url, data, params, inspector);
        Vue.prototype.$setDefaultHeader = (headerLabel, value) => setDefaultHeader(headerLabel, value);
        Vue.prototype.$removeDefaultHeader = (headerLabel) => removeDefaultHeader(headerLabel);
        Vue.prototype.$errors = Vue.observable(activeErrorHandler.errors);
    }
}

export default dataManager;