Vue.asyncComponent('ak-fab', {
    name:'ak-fab',
    props: {
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}, 'layout/ak-fab.html');